import React, { Component, Fragment} from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import { LOGIC_TYPES, QUESTION_LOGIC } from '../../../services/form.helper.service';
import { FormStore, conditionalMenuState } from '../../../services/form.store';
import { withStyles } from '@material-ui/styles';
import QuestionDialog from '../QuestionDialog'

const styles = theme => ({
  root: {},
  sideNavDivider: { marginLeft: 16 }
});

class AnswerLogicMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      index: props.index, showDialog: false, id:props.options[props.index].id,
      actions: [
        {
          label: "",
          children: [
            {
              label: LOGIC_TYPES.CAPTURE_IMAGE.label,
              name: LOGIC_TYPES.CAPTURE_IMAGE.name,
              icon: LOGIC_TYPES.CAPTURE_IMAGE.icon, isChecked: false
            },
            { label: LOGIC_TYPES.ADD_COMMENTS.label,
              name: LOGIC_TYPES.ADD_COMMENTS.name,
              icon: LOGIC_TYPES.ADD_COMMENTS.icon, isChecked: false
            },
            {
              label: LOGIC_TYPES.SHOW_HIDE_QUESTIONS.label,
              name: LOGIC_TYPES.SHOW_HIDE_QUESTIONS.name,
              icon: LOGIC_TYPES.SHOW_HIDE_QUESTIONS.icon, isChecked: false,
              targets: []
            }
          ]
        }
      ]
    }
  }

  componentDidMount() {
	  this.conditionalMenuState = conditionalMenuState.subscribe(index => {
      if (index !== -1) {
        this.setState({ index: index });
        let optionId = this.props.options[index].id;
        this.loadMenuForCurrentOption(optionId);
      }
    });
  }

  componentWillUnmount() {
    this.conditionalMenuState.unsubscribe();
  }

  loadMenuForCurrentOption = (optionId) => {
    if(optionId !== undefined){
      let actions = this.state.actions;
      actions[0].children.forEach((item) => {
        item.isChecked = this.isActionEnabled(item.name, optionId);
        let target = this.targetsFor(item.name, optionId);
        if (target !== undefined) {
          item.targets = target;
        }
      });
      this.setState({ actions: actions, id: optionId });
    }
	}
  
  conditionalFor = (optionId) => {
    if(optionId !== undefined) {
	    const conditional = FormStore.sharedInstance().conditionalsFor(optionId);
      return conditional;
    }
  }

  isActionEnabled = (name, optionId) => {
    const conditional = this.conditionalFor(optionId);
    if (conditional) {
      if (conditional.actions) {
        const filteredAction = conditional.actions.find(action => {
          return action.name === name;
        });
        return (filteredAction !== undefined);
      }
    }
    return false;
  }


  targetsFor = (name, optionId) => {
    const conditional = this.conditionalFor(optionId);
    if (conditional) {
      if (conditional.actions) {
        const filteredAction = conditional.actions.find(action => {
          return action.name === name;
        });
        if (filteredAction) {
          const targets = filteredAction.targets;
          if (targets !== undefined) {
            return targets;
          }
        }
      }
    }
    if (name === LOGIC_TYPES.SHOW_HIDE_QUESTIONS) {
      return [];
    }
    return  undefined;
  }

  handleToggleSwitch = (mainIndex, index) => (event) => {
    let newArr = this.state.actions;
    newArr[mainIndex].children[index].isChecked = event.target.checked;
    this.setState({ actions: newArr });
    const checkedItems = newArr[mainIndex].children.filter(item => { return item.isChecked === true });
    var actions = [];
    if (checkedItems.length > 0) {
      checkedItems.forEach((item) => {
        if (item.name !== LOGIC_TYPES.SHOW_HIDE_QUESTIONS.name) {
          var newAction = {
            label: item.label, name: item.name, icon: item.icon
          };
          actions.push(newAction);
        } else {
          var newShowAction = {
            label: QUESTION_LOGIC.SHOW.label, name: QUESTION_LOGIC.SHOW.name,
            icon: QUESTION_LOGIC.SHOW.icon
          };
          var newHideAction = {
            label: QUESTION_LOGIC.HIDE.label, name: QUESTION_LOGIC.HIDE.name,
            icon: QUESTION_LOGIC.HIDE.icon
          };
          newShowAction.targets = item.targets;
          newHideAction.targets = item.targets;
          actions.push(newShowAction);
          actions.push(newHideAction);
        }
      });
    }
    let optionId = this.state.id;
    FormStore.sharedInstance().addActionsForOption(optionId, actions);
    const action = newArr[mainIndex].children[index];
    if (action.isChecked &&
      action.name === LOGIC_TYPES.SHOW_HIDE_QUESTIONS.name) { /*If Conditionals then show question menu*/
      this.setState({showDialog: true});
    }
  };

  handleClose = () => {
    this.setState({ showDialog: false });
  }

  handleListItemClick = (e, item) => {
    if(item.isChecked && item.name === LOGIC_TYPES.SHOW_HIDE_QUESTIONS.name) {
      this.setState({ showDialog: true });
    }
  }

  render() {
      const { classes } = this.props;
      return (
          <Fragment>
          <div>
            {this.state.actions.map(({ ...item }, mainIndex) => (
              <span key={"main" + mainIndex}>
                <ListSubheader>
                  <Typography variant="overline" component="div" 
                	style={{ marginTop: 5 }}>{item.label}
                  </Typography>
                </ListSubheader>
                {item.children.map((child, index) => (
                  <span key={child.label + index}>
                    <MenuItem>
                      <ListItemText primary={child.label} 
                        onClick={(event) => this.handleListItemClick(event, child)} />
                      <ListItemSecondaryAction>
                        {("isChecked" in child) ?
                          <Switch edge="end" size="small" color="primary"
                            checked={child.isChecked}
                            onChange={this.handleToggleSwitch(mainIndex, index)}
                          />
                        : null}
                      </ListItemSecondaryAction>
                    </MenuItem>
                    <Divider light className={classes.sideNavDivider} />
                  </span>
                ))}
              </span>
            ))}
          </div>
          <QuestionDialog open={this.state.showDialog} id={this.state.id}
            onClose={this.handleClose} index={this.state.index}
          />
        </Fragment>
      )
  }
}

export default withStyles(styles)(AnswerLogicMenu);