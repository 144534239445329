import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Dialog } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    uppercase: {
        textTransform: 'uppercase'
    },
    title: {
        paddingBottom: "5px",
    },
    dialogPaper: {
        width: (props) => props.dialogWidth || 'auto', // Dynamically use the dialogWidth prop or default to auto
        maxWidth: 'none', // Removes the default maxWidth constraint
      },
}));


export default function ConfirmationDialog(props) {
    const { onClose, open, onDelete, description, title, type, onApprove,children,buttonTitle,mainButtonDisabled=false,dialogWidth } = props;
    const classes = useStyles({ dialogWidth });

    const handleCancel = () => {
        onClose();
    };

    const handleDelete = () => {
        onDelete();
    };
    
    const handleApprove = () => {
        onApprove();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle className={`${classes.uppercase} ${classes.title}`} disableTypography >{title}</DialogTitle>
                <DialogContent>
                    {
                        description&&
                        <DialogContentText id="alert-dialog-description">
                            {description}
                        </DialogContentText>
                    }
                    {
                        children&&children
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handleCancel} color="inherit">
                        Cancel
                    </Button>
                    {(type === 'delete') ? <Button onClick={handleDelete} variant="outlined" color="primary">
                        Delete
                    </Button> :
                        <Button onClick={handleApprove} variant="outlined" color="primary" disabled={mainButtonDisabled}>
                            {buttonTitle||'Approve'}
                        </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
