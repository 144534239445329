import { FormHelperService } from "../../services/form.helper.service";

export const DefaultElements = {
  'text': {
    "componentType": "text",
    "name": "text_id_1",
    "label": "Text Field Label",
    "dataType": "string",
    "buttonName": "Text",
    "optionsLabel": "Text",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false, "maxLength": 0, "numberOnly": false, "phoneNumber": false, "email": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "hasMaxLength": false, "hasOptionalEquipmentValue": "" },
  },
  'textarea': {
    "componentType": "textarea",
    "name": "text_id_1",
    "label": "Text Area Label",
    "dataType": "string",
    "buttonName": "Text Area",
    "optionsLabel": "Text Area",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false },
  },
  'radio': {
    "componentType": "radio",
    "name": "radio_id_1",
    "label": "Radio Group Label",
    "dataType": "string",
    "buttonName": "Radio Button",
    "optionsLabel": "Radio Button",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "options": [{ id: null, value: "Option Label" }, { id: null, value: "Option Label" }],
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": false, "answerLabel": "", "allowFileUpload": false, "conditional": false, "hasOptionalEquipmentValue": "" },
  },
  'checkbox': {
    "componentType": "checkbox",
    "name": "checkbox_id_1",
    "label": "Check Box Label",
    "dataType": "object",
    "buttonName": "Checkboxes",
    "optionsLabel": "Checkbox",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "options": ["Option Label", "Option Label"],
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "hasOptionalEquipmentValue": "" }
  },
  'select': {
    "componentType": "select",
    "name": "select_id_1",
    "label": "Select Box Label",
    "dataType": "string",
    "buttonName": "Select Box",
    "optionsLabel": "Select Box",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
   "options": [{ id: null, value:"Option Label" }, { id: null, value:"Option Label" }],
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "conditional": false, "multiple": false, "hasOptionalEquipmentValue": "" }
  },
  'date': {
    "componentType": "date",
    "name": "date_id_1",
    "label": "Installation date and time",
    "dataType": "string",
    "buttonName": "Date",
    "optionsLabel": "",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false, "dateFormat": 'DD/MM/YY' },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "preFilledDate": false, "hasHoursWorked":false, "hoursWorkedValue":0, "hasOptionalEquipmentValue":""}
  },
  'range': {
    "componentType": "range",
    "name": "range_id_1",
    "label": "Range Label",
    "dataType": "string",
    "buttonName": "Text",
    "optionsLabel": "Text",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false,
    "minRange": 1, "maxRange": 1, "rangeMultiplier": 1, "minRangeLabel": "Minimum Range Label", "maxRangeLabel":"Maximum Range Label", "defaultValue": 1 , "hasOptionalEquipmentValue": "" }
  },
  'fileInput': {
    "componentType": "fileInput",
    "name": "fileInput_id_1",
    "label": "Upload Turbine Images",
    "dataType": "file",
    "buttonIcon":"fa fa-file-upload-o",
    "buttonName":"File Upload",
    "optionsLabel": "File Upload",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "maxImages": 1, "maxImageSize": "1 MB",  "hasOptionalEquipmentValue": "" }
  },
  'dependentDropdown': {
    "componentType": "dependentDropdown",
    "name": "dependentDropdown_id_1",
    "label": "Dependent Dropdown Label",
    "dataType": "string",
    "buttonName": "Dependent Dropdown",
    "optionsLabel": "Dependent Dropdown",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false },
    "properties": {"visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false},
    "dynamicDropdownList": []
  },
  'barcode': {
    "componentType": "barcode",
    "name": "barcode_id_0",
    "label": "Barcode Label",
    "dataType": "string",
    "buttonName": "Barcode",
    "optionsLabel": "Barcode",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "barcodeType":"" }
  },
  'signature': {
    "componentType": "signature",
    "name": "signature_id_1",
    "label": "Lead Technician Signature",
    "dataType": "string",
    "buttonName": "Signature",
    "optionsLabel": "Signature",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "allowDuplicate":false,  "hasOptionalEquipmentValue": "" }
  },
  'helpimage': {
    "componentType": "helpimage",
    "name": "imageInput_id_0",
    "label": "Instructions/Help Label",
    "dataType": "string",
    "buttonName": "Image Upload",
    "optionsLabel": "Image Upload",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
    "businessUnit": "ren",
    "helpImageText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "validations": { "required": false },
    "properties": { "visible": true, "disabled": true, "hasHelpImage": false }
  },
  'section': {
    "componentType": "section",
    "name": "section_id_1",
    "label": "Untitled Section",
    "buttonIcon": "fa fa-window-maximize",
    "buttonName": "Section",
    "optionsLabel": "Section",
    "enabledActions": [],
    "enabledConditions": [],
    "children": [],
  },
  'subsection': {
    "componentType": "subsection",
    "name": "subsection_id_1",
    "label": "Untitled Sub Section",
    "buttonIcon": "fa fa-window-maximize",
    "buttonName": "Subsection",
    "optionsLabel": "Subsection",
    "enabledActions": [],
    "enabledConditions": [],
    "children": []
  }
}

export class ElementsDefault {

  static elementOfType(newType) {
    const defaultObj = DefaultElements[newType];
    if (defaultObj) {
      var newElement = {};
      newElement.componentType = defaultObj.componentType;
      newElement.name = defaultObj.name;
      newElement.label = defaultObj.label;
      newElement.buttonIcon = defaultObj.buttonIcon;
      newElement.buttonName = defaultObj.buttonName;
      newElement.optionsLabel = defaultObj.optionsLabel;
      newElement.enabledActions = [];
      newElement.enabledConditions = [];
      newElement.children = [];

      if (defaultObj.options && defaultObj.componentType === 'radio') {
        defaultObj.options.forEach((item) => {
          let elementId = FormHelperService.generateElementId();
          item.id = elementId;
        });
        newElement.options = defaultObj.options;
      }
      if (defaultObj.options && defaultObj.componentType === 'select') {
        defaultObj.options.forEach((item) => {
          let elementId = FormHelperService.generateElementId();
          item.id = elementId;
        });
        newElement.options = defaultObj.options;
      }

      if (defaultObj.options && (defaultObj.componentType === 'checkbox')) {
        newElement.options = defaultObj.options;
      }

      if (defaultObj.dynamicDropdownList) {
        newElement.dynamicDropdownList = defaultObj.dynamicDropdownList;
      }

      if (defaultObj.helpImageText) {
        newElement.helpImageText = defaultObj.helpImageText;
      }

      if (defaultObj.validations) {
        newElement.validations = defaultObj.validations;
        let validations = {};
        if (defaultObj.componentType === "text") {
          validations = { "required": false, "maxLength": 0, "numberOnly": false, "phoneNumber": false, "email": false }
        }
        else if (defaultObj.componentType === "date") {
          validations = { "required": false, "dateFormat": 'DD/MM/YY' };
        }
        else {
          validations = { "required": false };
        }
        newElement.validations = validations;
      }

      if (defaultObj.properties) {
        let properties = {};
        if (defaultObj.componentType === "checkbox") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "radio") {
          properties = { "visible": true, "disabled": false, "answerLabel": "", "allowFileUpload": false, "conditional": false, "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "select") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "conditional": false, "multiple": false, "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "text") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "hasMaxLength": false, "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "date") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "preFilledDate": false, "hasHoursWorked": false, "hoursWorkedValue": 0 , "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "signature") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "allowDuplicate": false, "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "barcode") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false, "barcodeType":"" };
        }
        else if (defaultObj.componentType === "textarea") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false };
        }
        else if (defaultObj.componentType === "helpimage") {
          properties = { "visible": true, "disabled": true, "hasHelpImage":false, };
        } else if (defaultObj.componentType === "range") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false,
                          "minRange": 1, "maxRange": 1, "rangeMultiplier": 1, "minRangeLabel": "Minimum Range Label", "maxRangeLabel":"Maximum Range Label", "defaultValue": 1, "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "fileInput") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "maxImages": 1, "maxImageSize": "1 MB", "hasOptionalEquipmentValue": "" };
        }
        else if (defaultObj.componentType === "dependentDropdown") {
          properties = { "visible": true, "disabled": true, "answerLabel": "", "allowFileUpload": false };
        }
        newElement.properties = properties;

      }
      return newElement;
    }

    return null;
  }

}
