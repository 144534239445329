import { Box, Card, Divider, Grid, IconButton, InputAdornment, TextField, Typography, MenuItem, ListItemText, ListItemIcon, TablePagination } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { FormStore, loaderState, myFormsPageState, myFormsState, sortingSubject } from '../store/form.store';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import FormListView from '../components/Form/FormListView';
import FormView from '../components/Form/FormView';
import FormFillerService from '../../services/review-portal-services/form-filler.service';
import { dateHelperService } from '../../services/date-helper.service';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CircularProgress from '@material-ui/core/CircularProgress';
import SortMenu from '../components/Sort';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MoreHoriz } from '@material-ui/icons';
import SearchDialog from '../../components/form-element/SearchDialog';
import { CSVLink } from 'react-csv';
import { FORM_CATEGORIES } from '../../services/review-portal-services/review-form.helper.service';
export var currentPage = 1;

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        margin: '2%',
    },
    layout: {
        position: 'relative',
    }, 
    rootSearch: {
        padding: theme.spacing(2),
        maxHeight: '600px',
        overflowY: 'auto'
    },
    flexGrow: {
        flexGrow: 1
    },
    searchBar: {
        width: '80%',
        "& input::placeholder":{fontSize:"small"}

    },
    formFields: {
        fontSize: '14px',
        padding: "0px 5px"
    },
    lastName: {
        fontSize: '14px',
        padding: "0px 2px"
    },
    initialPoint: {
        paddingRight: "35px"
    },
    clearAllIcon: {
        width: '80px',
        height: '80px',
        top: '10%',
        left: '50%',
        position: 'relative'
    },
    infoIcon: {
        width: '40px',
        height: '40px',
        textAlign: 'center'
    },
    formMessgae: {
        height: '300px',
        margin: '2%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    showMessage: {
        position: 'relative',
        top: '10%',
        left: '38%'
    },
    title: {
        padding: theme.spacing(2),
        fontWeight: 'bold'
    },
    selected: {
        color: '#242D3E',
        backgroundColor: "transparent !important",
        border: "1px solid rgba(36,45,62,0.25)"
    },
    pagination: {
        marginTop: "10px",
        display: "flex",
        justifyContent: 'center'
    },
    exportIcon: { 
        float: "right",
        padding: 0
    },
    menuItem: {
        "&:hover": {
            color: "#419BAC",
            backgroundColor: "#eff7f8"
        }
    },
    listIcon: {
        minWidth: 30
    },
    formLabel: {
        color: "#747a84"
    },
    menuItemText: {
        color:'black'
    }
});

class MyForms extends Component {
    constructor(props) {
        super(props);
        this.sortParam = { label: "", value: "", order: "" };
        this.totalPages = 0;
        this.state = {
            forms: [], title: 'My Forms', searchText: '', selected: [],
            sites: [], showForm: false, isChecked: false,
            showMessage: 'Please Select a form to display form Data',
            anchorEl: null, headers : [], csvData :[],
            clearAllSelected: false, noResultMessage: '', rowsPerPage: 20,
            sortParam: this.sortParam, showErrorMessage: true,
            currentPage: currentPage, totalItems: 0,
            formClicked : [], selectedData: [], isLoading:false
        };
        this.formFillerService = new FormFillerService();
    }

    componentDidMount() {
        this.formStateSubscription = myFormsState.subscribe((forms) => {
                this.setState({
                    forms: forms, searchText: '', showForm: false,
                    noResultMessage: '', isLoading: false
                });
                this.getCSVData(forms);
                this.clearSelected();
        });
        this.formStateSubscription = loaderState.subscribe((loader) => {
            loader ? this.setState({isLoading: true }) : this.setState({isLoading: false })
        })

        this.myformsPageStateSubscription = myFormsPageState.subscribe((response)=>{
            if(response !== undefined) this.onLoadAllForms(response);
        });
        this.sortSubscription = sortingSubject.subscribe(response => {
            this.setState({ sortParam: response });
        });
        this.setState({
            headers : [
                { label: "Form ID Data", key: "formDataId" },
                { label: "Form ID", key: "id" },
                { label: "Site ID", key: "siteId" },
                { label: "Site Name", key: "siteName" },
                { label: "Turbine ID", key: "turbineId" },
                { label: "Turbine Name", key: "turbineName" },
                { label: "Form Name", key: "form_name" },
                { label: "Form Version", key: "version" },
                { label: "Form Status", key: "formStatus" },
                { label: "Business", key:"business" },
                { label: "Sub Business", key: "subBusiness" },
                { label: "Group", key: "group" },
                { label: "Sub Group", key: "subGroup" },
                { label: "Created By", key: "createdBy" },
                { label: "Created On", key: "createdDate" },
                { label: "Last Updated By", key: "updateBy" },
                { label: "Last Updated On", key: "updatedDate" }
            ]
        });
    }

    getCSVData(forms){
        const data = forms.map(form => ({
            formDataId: form.formDataId,
            id: form.formId,
            siteId: form.siteId,
            siteName: form.siteName,
            turbineId: form.turbineId,
            turbineName: form.turbineName,
            form_name: form.formName,
            version: form.version,
            formStatus: form.formStatus,
            business: this.getCategory(form,FORM_CATEGORIES.BUSINESS),
            subBusiness: this.getCategory(form,FORM_CATEGORIES.SUB_BUSINESS),
            group: this.getCategory(form,FORM_CATEGORIES.GROUP),
            subGroup: this.getCategory(form,FORM_CATEGORIES.SUB_GROUP),
            createdBy: form.createBy,
            createdDate: dateHelperService.formattedDateFromUTS(form.createdDate),
            updateBy: form.updateBy,
            updatedDate: dateHelperService.formattedDateFromUTS(form.updatedDate)
          }));
          this.setState({csvData: data, selectedData: data});
    }

    getCategory(form, name) {
        let filtered = form.formCategories.filter(formCategory => formCategory.categoryType === name);
        if (filtered.length) {
            return filtered[0].categoryName;
        } else {
            return 'NA';
        }
    }
    onLoadAllForms(response) {
        this.totalPages = response.data.totalPages;
        this.setState({
            totalItems : response.data.totalItems
        });
        this.setState({ currentPage: response.data.currentPage}, () => {
          currentPage = this.state.currentPage;
          this.setState({ title: "MY FORMS (" + ((this.state.rowsPerPage * (this.state.currentPage )) + response.data.content.length) + ' of ' + response.data.totalItems + ")" });
        });
      }

    componentWillUnmount() {
        this.formStateSubscription.unsubscribe();
        this.sortSubscription.unsubscribe();
        this.myformsPageStateSubscription.unsubscribe();
    }

    startTimerForSearch(value) {
        if (value.length !== 0) {
          this.setState({
            searchText: value
          });
          const lowercasedValue = value.toLowerCase();
          const searchResult = this.state.forms.filter((item) => {
            const formStatusMatch =
              item.formStatus &&
              item.formStatus.toLowerCase().includes(lowercasedValue);
            const formNameMatch =
              item.formName &&
              item.formName.toLowerCase().includes(lowercasedValue);
            const formDataIdMatch =
              item.formDataId && item.formDataId.toString().includes(value);
              const turbineId=
              item.turbineId && item.turbineId.toString().includes(value);
            return (
              formNameMatch ||
              formDataIdMatch ||
              turbineId ||
              formStatusMatch
            );
          });
            if(searchResult.length > 0){
            this.setState({
              forms: searchResult,
                    title: 'MY FORMS(' + searchResult.length + ')',
                    noResultMessage:''
            });
            }else{
            this.setState({
                    noResultMessage:'No Results Found'
            });
          }
        } else {
          this.setState({
                searchText: ''
          });
          this.formStateSubscription = myFormsState.subscribe((forms) => {
                    if(forms.length>0){
                        this.setState({ forms: forms, noResultMessage: '', isLoading: false});
                        this.setState({ title: "MY FORMS (" + ((this.state.rowsPerPage * (this.state.currentPage)) + forms.length) + ' of ' + this.state.totalItems + ")"});
                    }
                    else{
                        this.setState({ noResultMessage: 'No results Found'});
                    }
          });
        }
    }

    showFormDataFromCheckBox = (selectedArray, selectedLength) => {
        if (selectedArray) {
            if (selectedLength > 1) {
                this.setState({
                    selected: selectedArray,
                    showForm: false, isChecked:true,
                    showMessage: 'Multiple Forms Selected',
                    title: selectedLength + ' FORM SELECTED',
                });
            } 
            if (selectedLength === 1) {
                FormStore.sharedInstance().load(selectedArray[0]);
                this.setState({
                    selected: selectedArray,
                    showForm: true, isChecked:true, showMessage: '',
                    title: selectedLength + ' FORM SELECTED'
                });
            } 
            if (selectedLength === 0) {
                this.setState({
                    selected: selectedArray,
                    showForm: false, isChecked:false,
                    showMessage: 'Please Select a form to display Form Data',
                    title: "MY FORMS (" + ((this.state.rowsPerPage * (this.state.currentPage)) + this.state.forms.length) + ' of ' + this.state.totalItems + ")",
                });
            }
        }
    };

    showFormDataFromCard = (formId) => {
        if (formId) {
            this.setState({
                showForm: true,
                showMessage: '',
                clearAllSelected: !this.state.clearAllSelected,
                title: "MY FORMS (" + ((this.state.rowsPerPage * (this.state.currentPage)) + this.state.forms.length) + ' of ' + this.state.totalItems + ")",
                isChecked: false, isLoading: false
            });
        } else {
            this.setState({
                showForm: false
            });
        }
        FormStore.sharedInstance().load(formId);
    }

    clearSelected = () => {
        this.setState({
            clearAllSelected: !this.state.clearAllSelected,
            title: "MY FORMS (" + ((this.state.rowsPerPage * (this.state.currentPage)) + this.state.forms.length) + ' of ' + this.state.totalItems + ")",
            showMessage: 'Please Select a form to display form Data',
            isChecked: false
        });
    }

    sortChanged = (sortParameter) => {
        FormStore.sharedInstance().sortForms(sortParameter);
    }

    onPageClicked = (event, page) => {
        currentPage = page + 1;
        this.setState({ currentPage: page, isLoading: true});
        FormStore.sharedInstance().pageChanged();
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.props.pageSizeCallBack(parseInt(event.target.value, 10) );
        this.setState({ currentPage: 0 });
        currentPage = 1;
        this.setState({ isLoading: true});
    };

    showExportOptionMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    onCloseExportMenu = () => {
        this.setState({
            anchorEl: null
        });
    }

    exportSelectedToPDF = () => {
        if (this.state.selected.length > 0 && this.state.selected.length <= 10) {
            const selected = this.state.selected;
            FormStore.sharedInstance().downloadSelectedForms(selected);
        } else if (this.state.selected.length === 0) {
            this.setState({ title: 'PLEASE SELECT FORM TO EXPORT' });
        } else if (this.state.selected.length > 10) {
            this.setState({ title: 'MAX 10 FORMS ONLY BE SELECTED' });
        }
    }

    handleClickedItem = (formItem)=>{
        this.setState({
            formClicked: formItem,
            isLoading: false
        });
    }

    handleIsLoading = () => {
        this.setState({
            isLoading: false
        });
    }

    exportToCSV = (event) => {
            if (this.state.selected.length > 0) {
                this.setState({
                    title: this.state.selected.length + ' FORM SELECTED'
                });
            } else {
                this.setState({ title: "MY FORMS (" + ((this.state.rowsPerPage * (this.state.currentPage)) + this.state.forms.length) + ' of ' + this.state.totalItems + ")" });
            }
          let selectedItems = [];
          this.state.selectedData.forEach((item) =>{
            if(this.state.selected.length>0 && this.state.selected.includes(item.formDataId)){
                selectedItems.push(item)
            }
            else if(this.state.selected.length===0 && (this.state.formClicked !== '' && this.state.formClicked === item.formDataId)){
                selectedItems.push(item)
            }
         })
         this.setState({csvData: selectedItems})
         selectedItems = [];
   }

    render() {
        const { classes } = this.props;
        return (<Fragment>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12} >
                        {this.state.forms.length > 0  && !this.state.isLoading ?
                            <Card className={classes.root} >
                                <Box>
                                    <Grid container>
                                        <Grid item md={5} xs={5} style={{ borderRight: "0.1em solid rgba(0, 0, 0, 0.12)" }}>
                                            <Typography variant="h6" className={classes.title}>
                                                {this.state.isChecked ? <IconButton edge="start">
                                                    <CloseIcon fontSize="inherit" onClick={this.clearSelected} />
                                                </IconButton> : null}{this.state.title}
                                                <IconButton id='iconButton' aria-label="threeDots" className={classes.exportIcon}>
                                                    <MoreHoriz id='moreHorizIcon' fontSize="inherit" onClick={this.showExportOptionMenu}/>
                                                </IconButton>
                                                <SearchDialog
                                                    open={Boolean(this.state.anchorEl)}
                                                    onCloseMenu={this.onCloseExportMenu}
                                                    name="exportFormMenu"
                                                    anchorEl={this.state.anchorEl} title="" description="">
                                                    <Typography className={classes.formLabel} variant="inherit">EXPORT:</Typography>
                                                    <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }} onClick={()=>{
                                                        this.exportToCSV();
                                                        this.setState({selected: [],formClicked: "",});
                                                        }}>
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <SaveAltIcon id='downloadIcon' />
                                                        </ListItemIcon>
                                                        <CSVLink headers={this.state.headers} data={this.state.csvData} filename="FieldWork_Review_FormData.csv">
                                                            <ListItemText className={classes.menuItemText} primary="All Checklists in CSV"></ListItemText>
                                                        </CSVLink>
                                                    </MenuItem>
                                                    <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }} onClick={this.exportSelectedToPDF}>
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <InsertDriveFileIcon id='pdfIcon' />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.menuItemText} primary="Selected Checklist as PDF" disableTypography></ListItemText>
                                                    </MenuItem>
                                                </SearchDialog>
                                            </Typography>
                                            <Divider />
                                            <Typography component="div" className={classes.rootSearch}>
                                                <TextField variant="outlined" fullWidth
                                                    className={classes.searchBar} 
                                                    id="search"
                                                    name="search"
                                                    placeholder="Search by Form name, Status, Turbine or RecordId"
                                                    autoComplete='off'
                                                    value={this.state.searchText}
                                                    onChange={event => {
                                                        this.startTimerForSearch(event.target.value)
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment>
                                                                <IconButton edge="start"
                                                                    onClick={this.handleExpandClick}>
                                                                    <SearchIcon id="searchIcon" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}>
                                                </TextField>
                                                <SortMenu sortParam={this.state.sortParam} onMenuClicked={(sortParameter) => this.sortChanged(sortParameter)} />
                                                <div><br /></div>
                                                <FormListView forms={this.state.forms} handleClickedItem={this.handleClickedItem} showFormDataFromCheckBox={this.showFormDataFromCheckBox} showFormDataFromCard={this.showFormDataFromCard} clearAllSelected={this.state.clearAllSelected} noResultMessage={this.state.noResultMessage}>
                                                </FormListView>
                                                </Typography>
                                                {Math.ceil(this.state.totalItems / this.state.rowsPerPage) > 1 ?
                                                <TablePagination className={classes.pagination}
                                                    rowsPerPageOptions={[10, 20, 50, 100,500]}
                                                    component="div"
                                                    count={this.state.totalItems}
                                                    rowsPerPage={this.state.rowsPerPage}
                                                    page={this.state.currentPage}
                                                    onChangePage={this.onPageClicked}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                /> : null}
                                        </Grid>
                                        {((this.state.showForm) && (this.state.showMessage === null || this.state.showMessage === '')) ?
                                            <Grid item md={7} xs={7} className={classes.layout}><FormView getCheckedCardDetails={this.showFormDataFromCard} handleIsLoading={this.handleIsLoading}></FormView></Grid> :
                                            <Grid item md={7} xs={7}>
                                                <ClearAllIcon className={classes.clearAllIcon} />
                                                <Typography className={classes.showMessage}>{this.state.showMessage}</Typography>
                                            </Grid>}
                                    </Grid>
                                </Box>
                            </Card> : null}
                            {this.state.isLoading ? <Card className={classes.formMessgae}><CircularProgress style={{ color: '#0000FF' }} disableShrink /></Card> :  null}
                            {(this.props.isSearchButtonClicked) && (this.state.forms.length === 0) && (!this.state.isLoading) ? <Card className={classes.formMessgae}>No results found</Card> :  null}
                    </Grid>
                </Grid>
            </Box>
        </Fragment>);
    }
}

export default withStyles(styles)(MyForms);