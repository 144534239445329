import './App.scss';
import '../shared/style/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component, Fragment } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../shared/theme/Theme';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from '../route/Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { UserInfoService } from '../services/user-info.service';
import FormBuilderService from '../services/form-builder.service';
import { loggedInState } from '../services/form.store';
import { setToken } from "../services/http-helper.service";
import { AccessToken } from "../utility/Constant";

const browserHistory = createBrowserHistory();

class App extends Component {
  constructor() {
    super();
    this.userInfoService = new UserInfoService();
    this.formBuilderService = new FormBuilderService();
    this.state = { userSSO: null, user: {}, userRole: '', isLoggedIn: false }
  }

  async getAccessToken() {
	  let accessToken = '', tokenType = '';
	  let localRole = localStorage.getItem('user_role');
	  let userRole = (localRole && localRole.length > 0) ? 
		AccessToken.scope[localRole] : AccessToken.scope.TECHNICIAN;
      await this.formBuilderService.getAccessToken(userRole).then((response) => {
    	  if (response && response.status === 200) {
    		  accessToken = (response.data && response.data.access_token) ? 
    		      response.data.access_token : '';
    		  tokenType = (response.data && response.data.token_type) ? 
        		      response.data.token_type : '';
    		  if(tokenType && tokenType.length > 0) 
        		  localStorage.setItem('token_type', tokenType);
        	  if(accessToken && accessToken.length > 0) {
        		  setToken(accessToken);
        		  localStorage.setItem('access_token', accessToken);
        	      this.userInfoService.getUserInfo().then((response) => {
        	    	  const sso = response;
            	      this.setState({
            	        userSSO: sso/* awaiting to get sso value from getUserInfo else we will get null value and we cannot able to view the application.*/
            	      }, () => {
            	        this.formBuilderService.getUserDetailsBySSO(this.state.userSSO).then((response) => {
            	          if (response.data) {
            	            this.setState({
            	              user: response.data, 
            	              userRole: response.data.userRole
            	            });
            	          }
            	        });
            	      });
        	      });
        	  }
    	  }
          return accessToken;
      });
  }

  async componentDidMount() {
    var bool = false;
    this.loggedInSubscription = loggedInState.subscribe(isLoggedIn => {
      bool = isLoggedIn;
      this.setState({ isLoggedIn: bool});
    });
    if (!bool) this.getAccessToken();
  }

  componentWillUnmount() {
    this.loggedInSubscription.unsubscribe();
  }

  render() {
    return (
      <Fragment>
        {this.state.userSSO && this.state.isLoggedIn ?
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={browserHistory}>
                <Routes userRole={this.state.userRole} />
              </Router>
            </MuiPickersUtilsProvider>
            <ToastContainer hideProgressBar={true} position='bottom-center' 
            	draggable={false} />
          </ThemeProvider>
          : null}
      </Fragment>
    );
  }
}

export default App;