import FormBuilderService from "./form-builder.service";
import { debugState, userObject } from "../utility/Constant";
import { FormStore } from "./form.store";
import { SessionManager } from "./session.manager";

const SSO_LOGIN_URL = process.env.REACT_APP_SSO_LOGIN;
const formBuilderService = new FormBuilderService();

export class UserInfoService {
  async getUserInfo() {
    let user = {}, userSSO = '';
    if (debugState) {
      userSSO = userObject.sub;
      localStorage.setItem('ge_sso', userSSO);
    } else {
      let ge_sso = localStorage.getItem('ge_sso');
      if (ge_sso === null || ge_sso === undefined || ge_sso === '') {
        userSSO = await this.loadUserDetails(); /* awaiting to get user details value from loadUserDetails else it will return as null or undefined because we have to set the ssoID to userID header in http helper service */
      } else {
        userSSO = ge_sso;
      }
    }
    formBuilderService.getUserDetailsBySSO(userSSO).then((response) => {
      if (response.data) {
        user = response.data;
        localStorage.setItem('user_role', user.userRole ? user.userRole : '');
        SessionManager.sharedInstance().updateCurrentUser(user);
      }
    });
    FormStore.sharedInstance().updateLoggingState(true);
    return userSSO;
  }

  async loadUserDetails() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    let routeUrl = '';
    routeUrl = code;
    if (routeUrl) {
      let userSSO = '';
      await formBuilderService.getUserInfo(routeUrl).then((response) => { /* awaiting to get user detais response from api because userSSO returns null and we cannot access the application without getting userdetails */
        if (response) {
          userSSO = JSON.parse(response.data.sub);
          localStorage.setItem('ge_sso', userSSO);
        }
      });
      return userSSO;
    } else {
      window.location.href = SSO_LOGIN_URL;
    }
  }
}