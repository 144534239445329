import axios from 'axios';

const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;
const instance = axios.create({ baseURL: BASE_URL_V2 });

instance.defaults.headers.common['tenantId'] = 'ren';

export const setToken = (token) => {
	if(token && token.length > 0)
		instance.defaults.headers.common['Authorization'] = `Bearer ` + token + ``;
}

export default instance;