import { ANSWER_VALUE, ANSWER_DESCRIPTION, IMAGE } from "../utilities/Constants";

//All Extract answer functions should be written here
export const isValid = (answer) => {
    if(typeof answer === 'object' && answer !== null){
        return true;
    }
    else{
        return answer && answer.length > 0;
    }
};

export const extractTextAnswerValue = (answer) => {
    if (isValid(answer)) {
        let userResponse = answer[0]; //text - array will contain exact one value.
        return userResponse.answerValue;
    }

    return undefined;
};


export const extractDateHoursValue = (answer) => {
    if (isValid(answer)) {
        let date;
        let hours;
        let userResponse = answer[0];
        if(userResponse){
            date = userResponse.answerValue ? userResponse.answerValue : '';
            hours = userResponse.answerDescription ? userResponse.answerDescription : '';
        }
        else{
            date = answer[ANSWER_VALUE];
            hours = answer[ANSWER_DESCRIPTION];
          }
        return {
            date: date,
            hours: hours,
        };
    }

    return undefined;
};

export const extractRangeValue = (answer) => {
    if (isValid(answer)) {
        if(!(Array.isArray(answer))){
            if(answer[ANSWER_VALUE]){
                return answer[ANSWER_VALUE]
            }
        }
    }
    return undefined;
};

export const extractBarcodeValue = (answer) => {
    if (isValid(answer)) {
        let userResponse = answer[0]; //Bar code - array will contain exact one value.
        return userResponse.answerValue;
    }

    return undefined;
};

export const extractCheckBoxValue = (answer) => {
    if (isValid(answer)) {
        let responses = [];
        answer.forEach((response) => {
            let value = response.answerValue;
            if (value) {
                responses.push(value);
            }
        })
        return responses;
    }
    return undefined;
};

export const extractCheckBoxIndices = (answer, checkboxOptions) => {
    let options = Object.assign([], checkboxOptions); // because element.options are 'readonly'
    if (isValid(answer)) {
        let responses = [];
        answer.forEach((response) => {
            let value = response.answerValue;
            let index = options.indexOf(value);
            if (value && index !== -1) {
                responses.push(index);
                delete options[index]; // For multiple occurrences with same label
            }
        });
        return responses;
    }
    return undefined;
}

export const extractDropDownValue = (answer) => {
    if (isValid(answer)) {
        let responses = [];
        if (Array.isArray(answer)) { // if answer is an array ---> Array [ {…} ]
        answer.forEach((item)=>{
        if (!Array.isArray(item)) { // if item is an object --> {"createdBy": "dev",...}
            for (const [key, value] of Object.entries(item)) {
            if(key === ANSWER_VALUE){
                if(typeof value==='string'){ // if value is coming as string
                    responses.push(value)
                }
                else if(typeof value === 'object'){ // if value is coming as object
                    value.forEach((insideItem)=>{
                        responses.push(insideItem.value)
                        })
                    }
                }
            }
            }
        })
        return responses
    }
    else{  // if answer is not an array ---> Object { 0: {…}, image: (1) […] }
        let answerValue = answer[0].answerValue;
          answerValue.forEach((insideItem)=>{
            responses.push(insideItem.value)
          })
          return responses
       }
    }
};

export const extractRadioValue = (answer) => {
    if (isValid(answer)) {
        let userResponse = answer[0]; //Radio - array will contain exact one value.
        if(typeof userResponse.answerValue === 'object'){
        let userResponseAnswerValue = userResponse.answerValue;
        let answerVal;
        Object.keys(userResponseAnswerValue).forEach(key => {
            if(key === 'value'){
                answerVal = userResponseAnswerValue[key];
            }})
            return answerVal;
        }
        else if(typeof userResponse.answerValue === 'string'){
            return userResponse.answerValue;
        }
    }
};

export const extractSignature = (answer) => {
    if (isValid(answer)) {
        let response = answer[0]; //Signature - value will contain base-64 string for image and description name.
        let signature = response.answerValue;
        let name = response.answerDescription;
        return {
            signature: signature,
            name: name,
        };
    }

    return undefined;
};


export const extractImageValue = (answer) => {
    if (isValid(answer)) {
        if(!(Array.isArray(answer))){
            if(answer[IMAGE]){
                return answer[IMAGE]
            }
        }
    }
    return undefined;
};
