export const BASE64_PATTERN = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

const computeBase64Labels = (isBase64Converter, label) => {
  if (isBase64Converter) {
    if (!BASE64_PATTERN.test(label)) {
      //Encode the string to a byte array (UTF-8)
      const encoder = new TextEncoder();
      const byteArray = encoder.encode(label);
      //Convert the byte array to a base64 string
      return btoa(String.fromCharCode(...byteArray));
    } else {
      return label;
    }
  } else {
    if (BASE64_PATTERN.test(label)) {
      const decodedBase64 = atob(label);
      const decoder = new TextDecoder();
      return decoder.decode(new Uint8Array(decodedBase64.split("").map(c => c.charCodeAt(0))));
    } else {
      return label;
    }
  }
};

export const htmlBase64Utils = (form, isBase64Converter) => {
    form?.children?.forEach((parent, parentIdx) => {
      parent?.children?.forEach((childs, childsIdx) => {
        if (childs?.buttonName !== 'Subsection') {
          childs.label = computeBase64Labels(isBase64Converter, childs?.label);
          if (childs?.helpImageText) {
            childs.helpImageText = computeBase64Labels(isBase64Converter, childs?.helpImageText);
          }
        } else if (childs?.buttonName === 'Subsection') {
          childs?.children?.forEach((child, childIdx) => {
            child.label = computeBase64Labels(isBase64Converter, child?.label);
            if (child?.helpImageText) {
              child.helpImageText = computeBase64Labels(isBase64Converter, child?.helpImageText);
            }
          })
        }
      })
    });
};

export const getLabelsText = (label) => {
  return BASE64_PATTERN.test(label) ? atob(label) : label;
};
