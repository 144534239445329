import React, { Component, Fragment } from 'react';
import { Box } from '@material-ui/core';
import { Typography, Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import {
  FormStore,
  currentSubsectionState,
  activeTocElementState
} from '../../services/form.store';
import Link from '@material-ui/core/Link';
import { getLabelsText } from '../../utility/CommonUtils';

const styles = theme => ({
  root: {
    paddingBottom: '0px',
    border: '1px solid rgba(0, 0, 0, .08)',  
    margin: "1%"
  },
  flexGrow: {
    flexGrow: 1,
  },
  fixedFooter: {
    top: 'auto',
    bottom: 0,
    width: 'calc(100% - 300px)',
  },
  sectionTitle: {
    padding: theme.spacing(1, 3)
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  questionBody: {
    borderTop: '1px solid rgba(0, 0, 0, .08)',
  },
});


class SubSectionList extends Component {

  constructor(props) {
    let componentType = 'subsection';
    super();
    this.state = {
      expanded: false,
      component: componentType,
      activeElement:null,
    }
  }

  componentDidMount() {
    this.sectionStateSubscription = currentSubsectionState.subscribe(newState => {
      this.setState({ expanded: newState === this.props.element.elementId });
    });

    this.activeTocElementState = activeTocElementState.subscribe(value => {
      if(value){
        this.setState({activeElement:value})
      }
    })
}

  componentWillUnmount() {
    this.sectionStateSubscription.unsubscribe();
  }

  handleExpandClick = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().openSubSection(this.props.element.elementId);
  };

  componentDidUpdate(prevProps, prevState){
    if(prevState.activeElement!==this.state.activeElement){
        FormStore.sharedInstance().getElementRef(this.state.activeElement).scrollIntoView({block: 'center'})
    }
  }
  
  handleClick = (elementId) =>{
    FormStore.sharedInstance().toggleTocDrawer(true, elementId)
    FormStore.sharedInstance().getElementRef(elementId).scrollIntoView({block: 'center'});
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography component="div" className={classes.root}>
              <Box display="flex" alignItems="center"
                className={classes.sectionTitle}
                style={{ color: (this.props.element.label.length <= 0) ? 'red' : 'black' }}>
                <Typography>
                  {(this.props.element.label.length) > 0 ? this.props.element.label : null}
                </Typography>
                <div className={classes.flexGrow} />
                <IconButton edge="end" id="iconButton"
                  onClick={this.handleExpandClick}>
                  <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expanded })} />
                </IconButton>
              </Box>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <RenderChildren subsection={this.props.element} classes={this.props} handleClick={this.handleClick} isActive={this.state.activeElement} />
              </Collapse>
        </Typography>
      </Fragment>

    )
  }
}

function RenderChildren(props) {
  const { classes } = props.classes;
  if (props.subsection !== undefined) {
    const items = props.subsection.children.map((item, key) =>
      <Fragment>
          <Box p={2} className={classes.questionBody}>
            <Fragment >
                <Link
                    component="button"
                    variant="string"
                    color={props.isActive === item.elementId?"primary":"secondary"}
                    onClick={()=>props.handleClick(item.elementId)}>
                    <span dangerouslySetInnerHTML={{ __html: getLabelsText(item?.label) }}></span>
                </Link>
            </Fragment>
          </Box>
      </Fragment>
    );
    return items;
  }
  return (<div> Empty Children </div>);
}

export default withStyles(styles)(SubSectionList);

