import { Box, Card, CardActionArea, Checkbox, Chip, Typography,Grid } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { dateHelperService } from '../../../services/date-helper.service';
import { withStyles } from '@material-ui/styles';
import FormFillerService from '../../../services/review-portal-services/form-filler.service';
import { labelStyle, FORM_STATUS } from '../../utilities/Constants';
import { NA } from "../../../constants/Constants";

const styles = theme => ({
    formFields: {
        fontSize: '14px',
        padding: "0px 5px",
    },
    lastName: {
        fontSize: '14px',
        padding: "0px 2px",
    },
    initialPoint: {
        paddingRight: "35px",
    },
    name:{
      fontFamily:'RobotoLight',
      fontSize:'14px',
      color:'#212121',
    },
    value:{
        color:'#212121',
        fontFamily:'RobotoLight',
        fontSize:'14px',
        fontWeight:'600'
    },
    gridContainer:{
      paddingTop: '16px',
      paddingBottom: "16px",
      justifyContent: "space-between",
    },
    checkbox:{
      paddingTop: "20px" 
    },
    formName:{
      wordBreak: "break-word",
      fontSize: "16px",
      fontWeight: "600",
      color: "#212121",
      fontFamily: "RobotoLight",
    },
    gridSpacing:{
      gap: "16px"
    },
    status:{
      color: "#001096",
      fontWeight: "600",
      fontFamily: "RobotoLight",
    }
});


class FormListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            selected: [],
            sites: [],
            formData: {},
            showFormData: false,
            isSelectedFormId: '',
        };
        this.isChecked = false;
        this.isSingleSelect = false;
        this.formFillerService = new FormFillerService();
        this.onSelectForm = this.onSelectForm.bind(this);
    }

    componentDidMount() {
        this.formFillerService.getAllSites().then(response => {
            this.setState({
                sites: response
            });
        });
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.clearAllSelected !== this.props.clearAllSelected) {
            this.setState({
                selected: []
            });
        }
    }


    getCategory(form, name) {
        let filtered = form.formCategories.filter(formCategory => formCategory.categoryType === name);
        if (filtered.length) {
            return filtered[0].categoryName;
        } else {
            return 'NA';
        }
    }

    onSelectCheckbox = (itemId) => {
        this.isChecked = true;
        const checkedIndex = this.state.selected.findIndex(element => element === itemId);
        let newSelected = [];
        if (checkedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, itemId);
        } else if (checkedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (checkedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (checkedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, checkedIndex),
                this.state.selected.slice(checkedIndex + 1),
            );
        }
        if (newSelected.length > 0) {
            this.isChecked = true
        } else {
            this.isChecked = false
        }
        this.setState({
            selected: newSelected,
            isSelectedFormId: ''
        });
        this.props.showFormDataFromCheckBox(newSelected, newSelected.length);
    }

    onSelectForm(item, event) {
        if (this.isChecked === false) {
            this.props.showFormDataFromCard(item.formDataId);
            this.setState({
                isSelectedFormId: item.formDataId
            })
        } else {
            this.isChecked = false
        }
        this.props.handleClickedItem(item.formDataId)
    }

    selectedFormColor = (id) => {
        if (this.state.isSelectedFormId === id) {
            this.isSingleSelect = true
        } else {
            this.isSingleSelect = false
        }
        return this.isSingleSelect;
    }

    render() {
        const { classes } = this.props;
        return (
          <Fragment>
                {this.props.noResultMessage.length === 0 ?
                (this.props.forms.map(({ ...item }, index) => (
                    <Card square variant="outlined" key={index} style={{ background: this.state.selected.includes(item.formDataId) ? '#e5f7f9' : this.selectedFormColor(item.formDataId) ? 'rgb(233 232 235)' : '' }}
                        onClick={(event) => this.onSelectForm(item, event)}>
                  <CardActionArea>
                    <Box>
                      <Grid className={classes.gridContainer}
                        container
                      >
                        <Checkbox
                          className={classes.checkbox}
                          checked={
                            this.state.selected.indexOf(item.formDataId) > -1
                          }
                          onClick={(event) =>
                            this.onSelectCheckbox(item.formDataId)
                          }
                        />

                        <Grid container xs={4} spacing={2}>
                          <Typography
                            variant="body1"
                            className={classes.formName}
                          >
                            {item.formName}
                          </Typography>
                          <Typography variant="body2" className={classes.name}>
                            Windfarm:{" "}
                            <span className={classes.value}>
                              {item.siteName
                                ? item.siteName
                                : NA}
                            </span>
                          </Typography>
                        </Grid>

                        <Grid
                          container
                          direction="column"
                          xs={3}
                          spacing={2}
                          className={classes.gridSpacing}
                        >
                          <Box>
                            <Typography
                              variant="body2"
                              className={classes.name}
                            >
                              Record Id:
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.value}
                            >
                              {item.formDataId}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              variant="body2"
                              className={classes.name}
                            >
                              Turbine
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.value}
                            >
                              {item.turbineId
                                ? item.turbineId
                                : NA}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          container
                          xs={3}
                          spacing={2}
                          className={classes.gridSpacing}
                        >
                          <Box>
                            <Typography
                              variant="body2"
                              className={classes.name}
                            >
                              Status:
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.status}
                            >
                              {item.formStatus?.toUpperCase()}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              variant="body2"
                              className={classes.name}
                            >
                              Completed Date
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.value}
                            >
                              {" "}
                              {dateHelperService.formattedDateFromUTS(
                                item.updatedDate
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardActionArea>
                </Card>
              ))
            ) : (
              <Typography>No Search Results found</Typography>
            )}
          </Fragment>
        );
    }
}

export default withStyles(styles)(FormListView);