import React, { Component, Fragment } from 'react';
import { Paper, TextField, withStyles, Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Titlebar from '../shared/component/Titlebar'
import Typography from '@material-ui/core/Typography';
import AddAssetMapping from '../components/form-element/AddAssetMapping';
import FormBuilderService from '../services/form-builder.service';
import AssetMappingTable from '../components/form-element/AssetMappingTable';
import FormFillerService from '../services/review-portal-services/form-filler.service';
import { Autocomplete } from '@material-ui/lab';
import { Loader } from '../components/Loader';

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    formControl: {
        width: '400px',
        margin: "7px 0px 7px 0px"
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
    },
    searchLabel: {
        paddingBottom: "8px"
    }
})

class AssetProfiling extends Component {
    constructor() {
        super();
        this.state = {
            sites: [],
            showDialog: false,
            selectedSite: '',
            selectedSiteId: '',
            showTable: false,
            showMessage: false,
            filteredData: [],
            isLoading: false,
            inputValue: '',
            turbineData: [],
        };
        this.formBuilderService = new FormBuilderService();
        this.formFillerService = new FormFillerService();
    }

    componentDidMount() {
        this.formFillerService.getAllSites().then(response => {
            this.setState({
                sites: response
            });
        });
    }

    filterOptions = () => {
        const searchTerm = this.state.inputValue.toLowerCase().trim();       
        const filteredOptions = this.state.turbineData.filter((option) => 
            option.turbineId.toString().toLowerCase().includes(searchTerm) 
            || option.shortName.toLowerCase().includes(searchTerm)
            || option.optionalEquipmentName.toLowerCase().includes(searchTerm));
            
        this.setState({ filteredData: filteredOptions });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.inputValue !== this.state.inputValue) {
            this.filterOptions();
        }
      }

    handleInputChange = (event, newInputValue, reason) => {
        if (reason === 'clear') {
            this.setState({ inputValue: '' });
          }
        else if (reason === 'input') {
            this.setState({ inputValue: newInputValue });
        }
    };
      
    onChangeSite = (event, value) => {
        this.setState({ isLoading: true, inputValue: '' });
        if (value) {
            let siteId = value.siteId;
            this.formBuilderService.getOptionalEquipmentMapping(siteId).then(response => {
                this.setState({
                    filteredData: response.data,
                    isLoading: false,
                    showTable: true,
                    showMessage: true,
                    turbineData: response.data,
                });
            });
            this.setState({
                selectedSite: value,
                selectedSiteId: value.siteId
            });
        }
        else {
            this.setState({
                filteredData: [],
                isLoading: false,
                showTable: true,
                showMessage: false,
                turbineData: [],
                selectedSite: '',
            });
        }
    }

    addMadpping = () => {
        this.setState({
            showDialog: true
        });
    }

    handleClose = () => {
        this.setState({
            showDialog: false
        });
    }

    onEnterSearch = (event) => {
        if (event.key === 'Enter') {
            event.stopPropagation();
        }
    }

    updateFilteredData = (newArray) => {
       if(newArray.length){
        this.setState({
            filteredData: newArray
        });
       }
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Titlebar title={'Asset Profiling'}>
                    <Button variant='contained' onClick={this.addMadpping} color="primary" disableElevation>Add Mapping</Button>
                </Titlebar>
                <Paper className={classes.root} component={'div'} elevation={0}>
                    <Typography className={classes.searchLabel} variant='h6'>Search</Typography>
                    <Box display="flex">
                        <Autocomplete
                            id='siteSearchId'
                            options={this.state.sites} freeSolo
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.properties.name}
                            onChange={this.onChangeSite}
                            style={{ width: "30%", marginRight: '24px' }}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    {option.properties.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="SitesTextField"
                                    variant="outlined"
                                    label="Select Sites"
                                    onKeyDown={this.onEnterSearch}
                                />
                            )}
                        />
                        {this.state.selectedSite &&
                            <Autocomplete
                                id='turbineSearchId'
                                options={[]}
                                freeSolo
                                inputValue={this.state.inputValue}
                                style={{ width: "30%" }}
                                disableOpenOnFocus
                                onInputChange={this.handleInputChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="TurbineTextField"
                                        variant="outlined"
                                        label="Search by Turbine or Optional Equipment"
                                        onKeyDown={this.onEnterSearch}
                                    />
                                )}
                            />
                        }
                    </Box>
                    <div>
                        <Loader position="center" loading={this.state.isLoading} />
                    </div>
                    {this.state.showTable && !this.state.isLoading ?
                        <AssetMappingTable filteredData={this.state.filteredData} showMessage={this.state.showMessage} selectedSiteId={this.state.selectedSiteId} /> : null}
                </Paper>
                {this.state.showDialog ? <AddAssetMapping open={this.state.showDialog} title='Optional Equipment Mapping'
                    selectedSite={this.state.selectedSite} selectedSiteId={this.state.selectedSiteId} updateFilteredData={this.updateFilteredData} onClose={this.handleClose} /> : null}
            </Fragment>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AssetProfiling);
