import { Avatar, Box, Card, Grid, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { extractDateHoursValue, extractImageValue } from '../../store/form.answer.extract';
import ImagePopup from './ImagePopup';
import { withStyles } from '@material-ui/styles';
import { currentSectionState, openSectionState } from '../../store/form.store';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import { dateAnswer, hoursAnswer } from '../../store/form.answer.fill';
import moment from 'moment';
import HoursWorkedInput from './HoursWorkedInput';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
});

class DateTimeElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            hoursWorked: '',
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: '',
            editable: false,
            dateFormat: 'dd/MM/yyyy',
            dateClicked: false,
            isShowDateTimePicker: false
        };
        this.dateFormat = '';
        this.formatted_date_format = '';
        this.moment_date_format = '';
    }

    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => { expanded = sectionId });
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded })
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload) {
            this.onLoadImage(this.props);
        }
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
        this.currentSectionSubscription.unsubscribe();
    }

    onLoadPage(props) {
        this.dateFormat = this.updatedDateFormat(this.props.element.validations.dateFormat);
        this.formatted_date_format = this.dateFormat.formatted_date_format;
        this.moment_date_format = this.dateFormat.moment_date_format;
        if (props.element.validations.dateFormat) {
            if (props.element.validations.dateFormat.toString().includes('HH')) {
                this.setState({
                    isShowDateTimePicker: true
                });
            }
            else {
                this.setState({
                    isShowDateTimePicker: false
                });
            }
        }
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractDateHoursValue(answer);
            if (userResponse) {
                let date = userResponse.date;
                if (date) {
                    this.setState({
                        date: date,
                        selectedDate: props.element.validations.dateFormat.toString().includes('HH') ? new Date(moment(date, this.moment_date_format).format("MM/DD/YY HH:mm")) : new Date(moment(date, this.moment_date_format).format("MM/DD/YYYY")),
                        dateClicked: false,
                    });
                }
                let hours = userResponse.hours;
                if (hours) {
                    this.setState({
                        hoursWorked: hours
                    });
                }
            }
        }
    }

    onLoadImage(props) {
        if (props.element) {
            let image = props.element.answer;
            let imageResponse = extractImageValue(image);
            if (imageResponse) {
                this.setState({
                    imageValue: imageResponse
                });
            }     
        }
    }

    onClickAvatar = (image) => {
        this.setState({
            imageOpen: true,
            imageUrl: image.imageUrl,
            imageName: image.imageName
        });
    }

    onClose = () => {
        this.setState({ imageOpen: false });
    }

    onChange = (event) => {
        let formattedDate = new Date(moment(event, this.moment_date_format).format("MM/DD/YY HH:mm"));
        let updatedDate = moment(event).format(this.moment_date_format).toString();
        this.setState({
            dateClicked: true,
            selectedDate: formattedDate,
            dateFormat: this.formatted_date_format
        })
        let answerNode = dateAnswer(updatedDate, this.props.element.answer);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }

    updatedDateFormat = (dateFormat) => {
        let momentFormat = '';
        switch (dateFormat) {
            case "DD/MM/YY":
                dateFormat = "dd/MM/yy";  //to display the format in date picker
                momentFormat = "DD/MM/YY"; // to send the selected date with the required format in dateAnswer functionality
                break;
            case "DD/MM/YYYY":
                dateFormat = "dd/MM/yyyy";
                momentFormat = "DD/MM/YYYY";
                break;
            case "MM/DD/YY":
                dateFormat = "MM/dd/yy";
                momentFormat = "MM/DD/YY";
                break;
            case "MM/DD/YYYY":
                dateFormat = "MM/dd/yyyy";
                momentFormat = "MM/DD/YYYY";
                break;
            case "DD/MM/YYYY HH.MM.SS":
                dateFormat = "dd/MM/yyyy HH:mm";
                momentFormat = "DD/MM/YYYY HH:mm:ss";
                break;
            case "YYYY/MM/DD HH.MM.SS":
                dateFormat = "yyyy/MM/dd HH:mm";
                momentFormat = "YYYY/MM/DD HH:mm:ss";
                break;
            case "YYYY/MM/DD HH.MMM.SS AM":
                dateFormat = "yyyy/MM/dd HH:MMM:ss:a";
                momentFormat = "YYYY/MM/DD HH:mm:ss";
                break;
            case "YYYY/MM/DD HH.MM AM":
                dateFormat = "yyyy/MM/dd HH:mm:a";
                momentFormat = "YYYY/MM/DD HH:mm";
                break;
            default:
                dateFormat = "dd/MM/yy";
                momentFormat = "DD/MM/YY";
        }
        let formatted_date_format = dateFormat;
        let moment_date_format = momentFormat;
        return { formatted_date_format, moment_date_format };
    }

    didUpdateState = () => {
        let answerNode = hoursAnswer(this.state.hoursWorked, this.props.element.answer);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }

    onChangeHoursWorked = (value) => {
        this.setState({ hoursWorked: value }, () => {
            this.didUpdateState();
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                {this.state.editable ?
                    (this.state.isShowDateTimePicker ?
                        <DateTimePicker
                            label={this.props.element.validations.dateFormat}
                            value={this.state.selectedDate}
                            onChange={this.onChange}
                            helperText={''}
                            error={false}
                            format={this.state.dateClicked ? this.state.dateFormat : 'dd/MM/yyyy HH:mm'}
                        /> :
                        <DatePicker
                            label={this.props.element.validations.dateFormat}
                            value={this.state.selectedDate}
                            onChange={this.onChange}
                            helperText={''}
                            error={false}
                            format={this.state.dateClicked ? this.state.dateFormat : 'dd/MM/yyyy'}
                        />)
                    : (<Typography>{this.state.date}</Typography>)}
                <br />
                {this.state.editable && this.props.element.properties.hasHoursWorked?
                    <HoursWorkedInput
                        defaultValue={this.state.hoursWorked}
                        elementId={this.props.element.elementId}
                        onChange={this.onChangeHoursWorked}
                    />
                    : <Typography>{(Number.parseInt(this.state.hoursWorked, 10) >= 0) ? "Hour(s) Worked " + this.state.hoursWorked : null}</Typography>}
                {(this.props.element.properties.allowFileUpload && this.state.imageValue?.length>0) ? this.state.imageValue.map((image) => (
                    image.imageUrl !=="" || image.imageName?
                    <Card className={classes.root} >
                        <Box>
                            <Grid container spacing={1}>
                                <Avatar alt={image.imageName} src={image.imageUrl} variant="square" style={{ height: '75px', width: '100px' }}
                                    onClick={() => this.onClickAvatar(image)} />
                            </Grid>
                        </Box>
                    </Card> : null
                )) : null}
                <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
            </Fragment>);
    }
}
export default withStyles(styles)(DateTimeElement);