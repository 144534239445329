import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import { IconButton, TablePagination, Toolbar, Typography, Tooltip } from '@material-ui/core';
import AlertDialog from './AlertDialog';
import { ALERT_DIALOGS } from '../../services/form.helper.service';
import { lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormBuilderService from "../../services/form-builder.service";
import { withStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';

const styles = theme => ({
    alignCenter: {
        textAlign: "center"
    }
});

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    title: {
        flex: '1 1 100%'
    }
}));

class AssetMappingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            page: 0,
            rowsPerPage: 20,
            showDeleteConfirmDialog: false,
            selectedSiteId: props.selectedSiteId,
            filteredData: props.filteredData
        }
        this.formBuilderService = new FormBuilderService();
    }

    componentDidUpdate(prevProps){
        if (prevProps.filteredData !== this.props.filteredData) {
            this.setState({
                selectedSiteId: this.props.selectedSiteId,
                filteredData: this.props.filteredData,
                selected:[]
            });
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 });
    };

    onRowClick = (event, name) => {
        const selectedIndex = this.state.selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1)
            );
        }
        this.setState({ selected: newSelected });
    }

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    onSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.filteredData.map((row) => row.id);
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    }

    handleDelete = (id) => {
        let selected = [];
        selected.push(id);
        if (selected.length) {
            this.setState({
                selected: selected,
                showDeleteConfirmDialog: true
            });
        }
    }

    handleMultiDelete = () => {
        this.setState({
            showDeleteConfirmDialog: true
        });
    }

    handleCancelDelete = () => {
        this.setState({
            showDeleteConfirmDialog: false
        });
    }

    handleConfirmDelete = () => {
        if (this.state.selected.length) {
            this.formBuilderService.deleteOptionalEquipmentMapping(this.state.selected).then(response => {
                if (response.data) {
                    toast.success("Mapping deleted successfuly!");
                    this.formBuilderService.getOptionalEquipmentMapping(this.state.selectedSiteId).then(response1 => {
                        this.setState({
                            filteredData: response1.data,
                            page: 0
                        });
                    });
                } else {
                    toast.error("Mapping could not be deleted due to errors!");
                }
            });
        }
        this.setState({
            showDeleteConfirmDialog: false,
            selected: []
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <EnhancedTableToolbar id='enchanced' numSelected={this.state.selected.length} handleDelete={this.handleMultiDelete} />
                {this.state.filteredData.length > 0 ? <TableContainer component={"div"} >
                    <Table aria-label="simple table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.filteredData.length}
                                        checked={this.state.filteredData.length > 0 && this.state.selected.length === this.state.filteredData.length}
                                        onChange={this.onSelectAllClick}
                                        inputProps={{ 'aria-label': 'select all' }}
                                    />
                                </TableCell>
                                <TableCell id='turbine' align="left">
                                    Turbine Name
                                </TableCell>
                                <TableCell id='equipment' align="left">Optional Equipment Name</TableCell>
                                <TableCell id='action' align="left">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.filteredData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((row) => (
                                    <TableRow key={row.id} role="checkbox"
                                        selected={this.isSelected(row.id)} tabIndex={-1}>
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={this.state.selected.indexOf(row.id) > -1} onClick={(event) => this.onRowClick(event, row.id)}/>
                                        </TableCell>
                                        <TableCell component="th" scope="row">{`${row.shortName} | ${row.turbineId}`}</TableCell>
                                        <TableCell align="left">{row.optionalEquipmentName}</TableCell>
                                        <TableCell align="left">
                                            <IconButton id='iconButton' aria-label="delete" onClick={() => this.handleDelete(row.id)}>
                                                <DeleteIcon id='deleteIcon' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer> : null}
                {Math.ceil(this.state.filteredData.length / this.state.rowsPerPage) > 1 ?
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={this.state.filteredData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    /> : null}
                {(this.state.showDeleteConfirmDialog) ?
                    <AlertDialog id='alertDialog'
                        open={this.state.showDeleteConfirmDialog}
                        onClose={this.handleCancelDelete}
                        handleOk={this.handleConfirmDelete}
                        dialogTitle={ALERT_DIALOGS.DELETE_FORM_TITLE}
                        dialogContent={ALERT_DIALOGS.DELETE_MAPPING_CONTENT}
                        cancelButton={ALERT_DIALOGS.DELETE_FORM_CANCEL}
                        confirmButton={ALERT_DIALOGS.DELETE_FORM_CONFIRM}
                    /> : null}
                {!this.state.filteredData.length && this.props.showMessage ?
                    <div id="noDataMessage" className={classes.alignCenter}>No data available</div> : null}
            </div>
        );
    }
}

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar id="toolbar"
            className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}>
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" 
                	variant="subtitle1" component="div">{numSelected} selected
                </Typography>
            ) : null}
            {numSelected > 0 ? (
                <Tooltip title="Delete" id='tooltip'>
                    <IconButton aria-label="delete" onClick={props.handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default withStyles(styles)(AssetMappingTable);