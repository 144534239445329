import React, { Component, Fragment } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/styles';
import { Toolbar } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormBuilderService from '../services/form-builder.service';
import { Box, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { FormStore, formState, siteArrayState } from '../services/form.store';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Collapse, IconButton, Typography, Card, Button, Chip, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import DialogFooter from './form-element/DialogFooter'
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = theme => ({
    flexGrow: {
        flexGrow: 1,
    },
    ptag: {
        padding: "0px 12px",
    },
    formControl: {
        margin: '4px',
        borderRadius: 4,
        border: '1px solid grey',
        padding: '10px',
    },
    formLabel: {
        padding: "0px 5px",
        color: "#747a84",
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolBar: {
        backgroundColor: '#242D3E',
        bottom: 0,
        top: 'auto',
        position: 'relative',
        width: '100%'
    },
    resetButton: {
        border: '1px solid white',
        color: 'white'
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
});


class SiteandRegion extends Component {
    constructor(props) {
        super();
        let formType = "updateForm";
        this.state = {
            tagged: 'Untagged',
            regions: [],
            sites: [],
            formRegion: [],
            formSiteRegion: [],
            valid: false,
            showAlert: false,
            checkedAllRegions: false,
            checkedAllSites: false,
            formType: formType,
            sitesTags: [],
        }
        this.formBuilderService = new FormBuilderService();
    }

    componentDidMount() {
        this.formBuilderService.getAllRegions().then(response => {
            this.setState({
                regions: response.data,
            })
            FormStore.sharedInstance().getAllSitesByRegionIds(response.data);
        });
        if (this.props.updateForm || this.props.copyForm) {
            this.formSubscription = formState.subscribe(response => {
                if(response.formRegion && response.formRegion.length){
                    this.setState({ formRegion:response.formRegion});
                    this.setState({ tagged:"Site Tagging" });  
                    if(response.formSiteRegion && response.formSiteRegion.length) {
                        let sites = []
                        response.formRegion.forEach((formRegion,index)=> {
                            this.formBuilderService.getAllSitesByRegion(formRegion).then(response1 => {
                                sites = sites.concat(response1.data)
                                if(index === (response.formRegion.length - 1)){
                                    this.setState({
                                        formSiteRegion:response.formSiteRegion
                                    });
                                    this.setState({ sites: sites});
                                    this.setState({
                                        sitesTags: sites.filter((site) => response.formSiteRegion?.includes(site?.siteId)),
                                    });
                                }
                            });
                        })
                       
                    }
                }
            });
        }
    }

    positionClick = (e, tagged) => {
        tagged && this.setState({ tagged: tagged });
        if(tagged === "Untagged"){
            this.setState({
                formRegion:[],
            });
            this.setState({
                formSiteRegion:[],
                sitesTags: [],
            });
            FormStore.sharedInstance().updateFormDetails("formSiteRegion",[]);
            FormStore.sharedInstance().updateFormDetails("formRegion",[]);
        }
    }

    regionChange = (event) => {
        if (event.target.value.includes("all")) {
            if (this.state.checkedAllRegions) {
                this.setState({
                    checkedAllRegions: false
                })
                this.setState({
                    formRegion: []
                });
                this.setState({
                    sites: [],
                });
                this.setState({ sitesTags: [], formSiteRegion: [] });
                FormStore.sharedInstance().getFormDetails("formRegion", []);
                if (this.props.updateForm || this.props.copyForm) {
                    FormStore.sharedInstance().updateFormDetails("formRegion", []);
                }
            } else {
                this.setState({ checkedAllRegions: true });
                this.setState({
                    formRegion: this.state.regions.map(region => region.siteGroupId)
                });
                FormStore.sharedInstance().getFormDetails("formRegion", this.state.regions.map(region => region.siteGroupId));
                if (this.props.updateForm || this.props.copyForm) {
                    FormStore.sharedInstance().updateFormDetails("formRegion", this.state.regions.map(region => region.siteGroupId));
                }
                this.siteArraySubscription = siteArrayState.subscribe(sites => {
                    let siteDropdown = [];
                    sites.forEach((site) => {
                        siteDropdown.push(...site.siteArray);
                        this.setState({ sites : siteDropdown });
                    });
                    this.setState({ sitesTags: this.state.sitesTags?.filter((tag) => siteDropdown?.some((site) => site?.siteId === tag?.siteId)) });
                    this.setState({ formSiteRegion: this.state.formSiteRegion?.filter((tag) => siteDropdown?.some((site) => site?.siteId === tag?.siteId)) });
                });
            }
        } else {
            this.setState({ formRegion: event.target.value });
            this.setState({ checkedAllRegions: false });
            FormStore.sharedInstance().getFormDetails("formRegion", event.target.value);
            if (this.props.updateForm || this.props.copyForm) {
                FormStore.sharedInstance().updateFormDetails("formRegion", event.target.value);
            }
            const regionId = event.target.value;
            if (event.target.value.length > 0) {
                this.siteArraySubscription = siteArrayState.subscribe(sites => {
                    let siteDropdown = [];
                    sites.forEach((site) => {
                        regionId.map((regionId) => {
                            if(site.regionId === regionId){
                                siteDropdown.push(...site.siteArray);
                                this.setState({ sites : siteDropdown });
                            }
                        });
                    });
                    this.setState({ sitesTags: this.state.sitesTags?.filter((tag) => siteDropdown?.some((site) => site?.siteId === tag?.siteId)) });
                    this.setState({ formSiteRegion: this.state.formSiteRegion?.filter((tag) => siteDropdown?.some((site) => site?.siteId === tag?.siteId)) });
                });
            } else {
                this.setState({ sites:[], formSiteRegion: [], sitesTags: [] });
            }
        }
    };


    alertClose = () => {
        this.setState({
            showAlert: false,
        })
    };

    siteChange = (event, value) => {
        if(value.length){
            this.setState({
                formSiteRegion: value.map(site => site.siteId),
                sitesTags: value.map(site => site)
            })
            FormStore.sharedInstance().getFormDetails("formSiteRegion", value.map(site => site.siteId));
            if (this.props.updateForm || this.props.copyForm) {
                FormStore.sharedInstance().updateFormDetails("formSiteRegion", value.map(site => site.siteId));
            }
        }
    };


    selectedRegionValues = (value) => {
        let newArr = this.state.regions.filter(region => value.includes(region.siteGroupId)).map(region => region.siteGroupName);
        return newArr.join(", ")
    }

    formResetHandler = () => {
        this.setState({
            formRegion: [],
            formSiteRegion: [],
            showAlert: false,
            checkedAllRegions: false,
            checkedAllSites: false,
            sites: [],
            sitesTags: [],
        });
    }


    formSubmitHandler = () => {
        let isValid = this.props.validate.validateForm();
        this.setState({ valid: isValid })
        this.setState({ showAlert: !isValid })
        FormStore.sharedInstance().checkFormValid(isValid);
        if (isValid === true) {
            FormStore.sharedInstance().getFormDetails("formSiteRegion", this.state.formSiteRegion);
            const formValues = FormStore.sharedInstance().sendFormDetails();
            this.formBuilderService.createForm(formValues).then((res) => {
                if (res.data) {
                    const formId = res.data;
                    this.props.history.push('/editForm/' + formId);
                    toast.success("Form created successfuly!");
                } else {
                    toast.error("Form could not be created due to errors!")
                }
            });

        }
    }

    closeDialog = () => {
        FormStore.sharedInstance().showDialog(false)
    }

    formChangeHandler = () => {
        let isValid = this.props.validate.validateForm();
        this.setState({ valid: isValid })
        this.setState({ showAlert: !isValid })
        FormStore.sharedInstance().checkFormValid(isValid);
        if (isValid) {
            if (this.props.updateForm) {
                FormStore.sharedInstance().updateFormMetaData(this.state.formType);
                return;
            } else if (this.props.copyForm) {
                this.setState({
                    formType: "copyForm"
                }, () => {
                    FormStore.sharedInstance().updateFormMetaData(this.state.formType)
                });
            } else {
                return;
            }
        }
    }

    render() {
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        const { classes } = this.props;
        return (
            <div>
                <Fragment>
                    <Card square variant="outlined" style={{ width: "96%", margin: "2%" }}>
                        <Toolbar  >
                            <Typography variant="h6">Site &amp; Region Restriction</Typography>
                            <div className={classes.flexGrow} />
                            <ToggleButtonGroup size="small" exclusive aria-label="text alignment" value={this.state.tagged} onChange={this.positionClick}>
                                <ToggleButton value="Untagged" aria-label="Untagged">Untagged</ToggleButton>
                                <ToggleButton value="Site Tagging" aria-label="Site Tagging">Site Tagging</ToggleButton>
                            </ToggleButtonGroup>
                        </Toolbar>
                        <p className={classes.ptag}>If this form is to be tagged with specific site, please select Site Tagging. </p>
                        {(this.state.tagged === "Site Tagging") ?
                            <Box p={3}>
                                <Grid container spacing={3} >
                                    <Grid item md={5} xs={10}>
                                        <Typography className={classes.formLabel} >SELECT REGIONS</Typography>
                                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                            <Select
                                                className={classes.underline}
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={this.state.formRegion}
                                                onChange={this.regionChange}
                                                input={<Input />}
                                                renderValue={(selected) => this.selectedRegionValues(selected)}>
                                                {(this.state.regions.length > 0) ?
                                                    <MenuItem key="all" value="all">
                                                        <Checkbox checked={this.state.checkedAllRegions} />
                                                        <ListItemText primary="Select All" />
                                                    </MenuItem> : null}
                                                {this.state.regions.map((region) => (
                                                    <MenuItem key={region.siteGroupName} value={region.siteGroupId}>
                                                         <Checkbox checked={this.state.formRegion.indexOf(region.siteGroupId) > -1} />
                                                        <ListItemText primary={region.siteGroupName} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={1} xs={2} ></Grid>
                                    <Grid item md={5} xs={10} >
                                        <Typography className={classes.formLabel}>SELECT SITES</Typography>
                                        <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={this.state.sites}
                                            onChange={this.siteChange}
                                            disableCloseOnSelect
                                            value={this.state.sitesTags}
                                            getOptionLabel={(option) => option ? option.properties ? option.properties.name : '':''}
                                            renderTags={(value, getTagProps) => value.map((option, index) =>
                                            (<Chip
                                                id="chip"
                                                size="small"
                                                label={option ? option.properties ? option.properties.name : '':''} {...getTagProps({ index })} />))}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        id="checkboxIcon"
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected} />
                                                    {option ? option.properties ? option.properties.name : '':''}
                                                </React.Fragment>)}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    id="siteTextField"
                                                    variant="outlined"
                                                    required />
                                            )} />
                                    </Grid>
                                </Grid>
                            </Box> : null}
                        <br></br>
                        <Collapse in={this.state.showAlert}>
                            <Alert variant="filled" severity="error"
                                action={<IconButton aria-label="close" color="inherit" size="small" onClick={this.alertClose}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>}>
                                You have missed out few validations! Please check the FormDetails page and fill the fields with proper values!
                                    </Alert>
                        </Collapse>
                        {(this.props.updateForm || this.props.copyForm) ?
                            <DialogFooter
                                cancel={this.closeDialog}
                                disabled={this.isEnabled}
                                save={this.formChangeHandler}
                                copyForm={this.props.copyForm}
                            /> :
                            <AppBar className={classes.appBar}>
                                <Toolbar className={classes.toolBar} >
                                    <span className={classes.flexGrow} />
                                    <Button id="reset" className={classes.resetButton} variant="outlined" color='inherit' disableElevation onClick={this.formResetHandler}>Reset</Button>
                                    <Button variant="contained" disableElevation onClick={this.formSubmitHandler}>Create Form</Button>
                                </Toolbar>
                            </AppBar>}
                    </Card>
                </Fragment>
            </div>
        );
    }
}

export default withStyles(styles)(SiteandRegion);
