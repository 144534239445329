import React, { Component } from 'react';
import { Typography, Box, Grid} from '@material-ui/core';
import clsx from 'clsx';
import { formErrorState, FormStore } from '../../../store/form.store';
import { withStyles } from '@material-ui/styles';
import Components from '../../form-element/CompMap';
import FormErrorView from './FormErrorView';
import { getLabelsText } from '../../../../utility/CommonUtils';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
    },
    sectionBody: {
        borderTop: '1px solid rgba(0, 0, 0, .08)',
        '&:hover': {
            backgroundColor: '#FCFCFC',
        },
    },
    activeQ: {
        backgroundColor: 'rgba(91, 195, 115, .1)',
        border: '1px solid rgb(91, 195, 115)',
        '&:hover': {
            backgroundColor: 'rgba(91, 195, 115, .1)',
        },
    },
    questionText: {
        color: '#00000094',
    },
});

class FormQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.element,
            errors: [],
        };
    }

    componentDidMount() {
        if (this.props.element !== undefined) {
            this.formErrorObservable = formErrorState.subscribe((response) => {
                if (response) {
                    if (response[this.props.element.id]) {
                    this.setState({ errors: response[this.props.element.id] });
                        return;
                    }
                    this.setState({ errors: [] });
                }
            });
        }
    }

    componentWillUnmount() {
        this.formErrorObservable.unsubscribe();
    }

    onChange(answer, element) {
        FormStore.sharedInstance().recordAnswer(answer, element);
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Box onClick={this.onClickQuestion} className={clsx(classes.sectionBody, { [classes.activeQ]: this.state.active })}>
                    <Box p={3}>
                        <Grid container>
                            <Grid item md={6} xs={6}>
                                <Typography className={classes.questionText}>
                                    <span dangerouslySetInnerHTML={{ __html: getLabelsText(this.props.element.label) }}></span>
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <RenderElement 
                                    onChange={this.onChange}
                                    errors={this.state.errors}
                                    element={this.props.element}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        );
    }
}

const RenderElement = (props) => {
    if (props.element !== undefined) {
        if (props.element.componentType !== undefined) {
        	const Element = Components[props.element.componentType];
            return (
                <>
                    <Element element={props.element}
                        hasError={props.errors && props.errors.length > 0}
                        onChange={props.onChange}
                    />
                    {props.errors && props.errors.length > 0 ? <RenderError errors={props.errors} /> : null}
                </>
            );
        }
    }
    return <div> Invaild component type </div>;
}

const RenderError = (props) => {
    return props.errors.map((error) => {
        return <FormErrorView errorMessage={error} />
    });
}

export default withStyles(styles)(FormQuestions);