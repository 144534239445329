import { ElementsDefault } from '../components/form-element/ElementsDefault';
import _ from 'lodash';
import { COMPONENT_TYPE } from '../constants/Constants';

export const ELEMENT_TYPE = Object.freeze({
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  DROPDOWN: 'dropdown',
});

export const ATTRIBUTES = Object.freeze({
  ANSWER_HINT: 'answerHint',
  ALLOW_FILE_UPLOAD: 'allowFileUpload',
  ANSWER_LOGIC: 'conditional',
  MULTI_SELECT: 'multiple',
  HAS_MAX_LENGTH: 'hasMaxLength',
  MAX_LENGTH: 'maxLength',
  NUMBER_ONLY: 'numberOnly',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  PRE_FILLED_DATE: 'preFilledDate',
  HAS_HOURS_WORKED: 'hasHoursWorked',
  HOURS_WORKED_VALUE: 'hoursWorkedValue',
  HAS_OPTIONAL_EQUIPMENT_VALUE: 'hasOptionalEquipmentValue',
  ALLOW_DUPLICATE: 'allowDuplicate',
  HAS_HELP_IMAGE: 'hasHelpImage',
  PLACEHOLDER: 'placeHolder',
  MAX_IMAGES: 'maxImages',
  MAX_IMAGE_SIZE: 'maxImageSize',
  MIN_MAX_RANGE_LABEL: 'rangeLabel',
  INSTRUCTION_HELP_TEXT: 'helpImageText',
  QUESTION_LABEL: 'questionLabel',
});

export const ACTIONS = Object.freeze({
  ADD: 'add',
  DELETE: 'delete',
  EDIT: 'edit',
  MOVE: 'move',
});

export const ANSWER_OPTION_LABELS = Object.freeze({
  LINK_OPTIONAL_EQUIPMENT: 'Link Optional Equipment',
  LINK_DATA_SOURCE: 'Link Data Source',
  ASSIGN_UNIQUE_CODE: 'Link Unique ID',
});

export const SORT_MENU_OPTIONS = Object.freeze([
  { label: 'Name', value: 'title' },
  { label: 'Language', value: 'defaultLanguageCode' },
  { label: 'Version', value: 'version' },
  { label: 'Status', value: 'formStatus' },
  { label: 'Owner', value: 'createdByName' },
  { label: 'Last Edit', value: 'lastUpdatedDate' },
  { label: 'Business', value: 'formCategories.category' },
  { label: 'Sub-Business', value: 'formCategories.category' },
  { label: 'Group', value: 'formCategories.category' },
  { label: 'Sub-Group', value: 'formCategories.category' },
]);

export const FORM_LIST_HEADERS = Object.freeze([
  { id: 'formName', label: 'Form Name', value: 'title' },
  { id: 'lang', label: 'Lang', value: 'defaultLanguageCode' },
  { id: 'ver', label: 'Ver.', value: 'version' },
  { id: 'status', label: 'Status', value: 'formStatus' },
  { id: 'owner', label: 'Owner', value: 'createdByName' },
  { id: 'lastEdit', label: 'Last Edit', value: 'lastUpdatedDate' },
  { id: 'business', label: 'Business', value: 'formCategories.category' },
  { id: 'subBusiness', label: 'Sub-Business', value: 'formCategories.category' },
  { id: 'group', label: 'Group', value: 'formCategories.category' },
  { id: 'subGroup', label: 'Sub-Group', value: 'formCategories.category' },
]);

export const EXISTING_FORMS = Object.freeze({
  SIZE: 20,
});

export const ALERT_DIALOGS = Object.freeze({
  UNSAVED_CHANGES_TITLE: 'Are you sure?',
  UNSAVED_CHANGES_CONTENT: 'You want to proceed without saving?',
  UNSAVED_CHANGES_CANCEL: 'No',
  UNSAVED_CHANGES_CONFIRM: 'Yes',
  DELETE_FORM_TITLE: 'Are you sure?',
  DELETE_GROUP_CONTENT: 'Do you wish to delete this Group?',
  DELETE_FORM_CONTENT: 'Do you wish to delete this form?',
  DELETE_MAPPING_CONTENT: 'Do you wish to delete this mapping?',
  DELETE_USER_CONTENT: 'Do you wish to delete this user?',
  DELETE_FORM_CANCEL: 'Cancel',
  DELETE_FORM_CONFIRM: 'Delete',
  LOGOUT_TITLE: 'Logout',
  LOGOUT_CONTENT: 'Are you sure you want to log out?',
  LOGOUT_CANCEL: 'Cancel',
  LOGOUT_CONFIRM: 'Confirm',
});

export const LOGIC_TYPES = Object.freeze({
  CAPTURE_IMAGE: {
    label: 'Capture Image',
    name: 'capture_image',
    icon: 'fas fa-camera',
  },
  ADD_COMMENTS: {
    label: 'Add Comments',
    name: 'add_comments',
    icon: 'fas fa-comment',
  },
  SHOW_HIDE_QUESTIONS: {
    label: 'Show/Hide Question',
    name: 'show',
    icon: 'fa-eye',
  },
});

export const QUESTION_LOGIC = Object.freeze({
  SHOW: {
    label: 'Show this',
    name: 'show',
    icon: 'fa-eye',
  },
  HIDE: {
    label: 'Hide this',
    name: 'hide',
    icon: 'fa-eye',
  },
});

export const FORM_CATEGORIES = Object.freeze({
  BUSINESS: 'Business',
  SUB_BUSINESS: 'Sub-Business',
  GROUP: 'Group',
  SUB_GROUP: 'Sub-Group',
});

export const ROLES = Object.freeze({
  GLOBALADMIN: 'GLOBALADMIN',
  ASSETMANAGER: 'ASSETMANAGER',
  SITEMANAGER: 'SITEMANAGER',
  TECHNICIAN: 'TECHNICIAN',
});

export class FormHelperService {
  static getAllFormElements(form) {
    const loop = array => {
      return array.reduce((flat, item) => {
        flat = flat.concat(item);
        if (item.children) {
          flat = flat.concat(loop(item.children));
        }
        return flat;
      }, []);
    };
    return loop(form.children);
  }

  static getElementNameList(form) {
    return this.getAllFormElements(form).map(e => e.name);
  }

  static generateElementName(componentType, namesArray) {
    const checkName = name => {
      const regex = '^' + componentType + '_id_\\d+$';
      return new RegExp(regex).test(name);
    };

    const getId = name => {
      const regex = '^' + componentType + '_id_(\\d+)$';
      return parseInt(new RegExp(regex).exec(name)[1]);
    };

    const lowestUnusedNumber = (sequence, startingFrom) => {
      const arr = sequence.slice(0);
      arr.sort((a, b) => a - b);
      return arr.reduce((lowest, num, i) => {
        const seqIndex = i + startingFrom;
        return num !== seqIndex && seqIndex < lowest ? seqIndex : lowest;
      }, arr.length + startingFrom);
    };

    const filteredIds = namesArray.filter(n => checkName(n)).map(n => getId(n));
    return componentType + '_id_' + lowestUnusedNumber(filteredIds, 0);
  }

  static generateElementId() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
      return (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
        16
      );
    });
  }

  static changeElement(form, id, newType) {
    var updatedForm = form;
    /*1. Iterate through all individual elements.*/
    updatedForm.children.forEach(section => {
      if (section.componentType === 'section') {
        section.children.forEach(child => {
          if (child.componentType === 'subsection') {
            child.children.forEach(subChild => {
              if (subChild.elementId === id) {
                FormHelperService.updateTypeOf(subChild, newType);
                return;
              }
            });
          } else {
            if (child.elementId === id && child.componentType !== 'subsection') {
              FormHelperService.updateTypeOf(child, newType);
              return;
            }
          }
        });
      }
    });

    return updatedForm;
  }

  static updateTypeOf(child, newType) {
    const newElement = ElementsDefault.elementOfType(newType);
    if (newElement) {
      child.children = [];
      child.componentType = newElement.componentType;
      child.enabledActions = newElement.enabledActions;
      child.enabledConditions = newElement.enabledConditions;
      child.name = newElement.name;
      child.buttonName = newElement.buttonName;
      child.optionsLabel = newElement.optionsLabel;
      if (newElement.options) {
        if (child.options === undefined || child.options === null || child.options === '') {
          child.options = newElement.options;
        }
      }
      if (newElement.dynamicDropdownList) {
        child.dynamicDropdownList = newElement.dynamicDropdownList;
      }
      if (newElement.helpImageText) {
        child.helpImageText = newElement.helpImageText;
      }
      child.placeHolder = newElement.placeHolder;
      child.validations = newElement.validations;
      child.properties = newElement.properties;
    }
  }

  static newElementOfType(type, form) {
    var element = ElementsDefault.elementOfType(type);
    if (element) {
      var namesArray = FormHelperService.getElementNameList(form);
      element.name = FormHelperService.generateElementName(element.componentType, namesArray);
      element.elementId = FormHelperService.generateElementId();
      return element;
    }
  }

  static addNewSectionToForm(sectionid, form, prefix) {
    if (form !== undefined) {
      var newSection = FormHelperService.newElementOfType('section', form);
      if (sectionid !== undefined) {
        const sectionIndex = element => element.elementId === sectionid;
        const index = form.children.findIndex(sectionIndex);
        if (index !== -1) {
          if (prefix) {
            const preIndex = index === 0 ? 0 : index - 1;
            form.children.splice(preIndex, 0, newSection);
          } else {
            form.children.splice(index + 1, 0, newSection);
          }
          return newSection;
        }
      }
      form.children.push(newSection);
      return newSection;
    }
    return null;
  }

  static getSectionElementsData(form, elementData) {
    let newSectionObj = structuredClone(elementData);
    let namesArray = FormHelperService.getElementNameList(form);
    newSectionObj.name = FormHelperService.generateElementName(
      newSectionObj.componentType,
      namesArray
    );
    newSectionObj.elementId = FormHelperService.generateElementId();
    if((newSectionObj[COMPONENT_TYPE] === 'radio') || (newSectionObj[COMPONENT_TYPE] === 'select')){
      let newOptions = newSectionObj?.options;
      let newConditions = newSectionObj?.conditional;
      let generateId = FormHelperService.generateElementId();
      newOptions?.forEach((option,index)=>option.id = generateId + `${index}`)
      newConditions?.forEach((condition,index)=>condition.id = generateId + `${index}`)
      return {...newSectionObj,options:newOptions,conditional:newConditions};
    }
    return newSectionObj;
  }

  static copySection(form, existedSection) {
    let newSection = FormHelperService.getSectionElementsData(form, existedSection);
    newSection.label = 'Copy - ' + newSection.label;
    newSection.children = [];
    const existedIndex = element => element.elementId === existedSection.elementId;
    const index = form.children.findIndex(existedIndex);
    if (index !== -1) {
      let sectionIndex = index + 1;
      form.children.splice(sectionIndex, 0, newSection);

      existedSection.children.forEach(elem => {
        let newObj = FormHelperService.getSectionElementsData(form, elem);
        form.children[sectionIndex].children.push(newObj);
        if (elem.children.length > 0) {
          let subind = form.children[sectionIndex].children.length - 1;
          form.children[sectionIndex].children[subind].children = [];
          elem.children.forEach(subelem => {
            let newsubObj = FormHelperService.getSectionElementsData(form, subelem);
            form.children[sectionIndex].children[subind].children.push(newsubObj);
          });
        }
      });
    }
  }

  static addNewElementTo(form, section, type, subsectionId) {
    var element = FormHelperService.newElementOfType(type, form);
    if (subsectionId !== undefined && subsectionId.length > 0) {
      let childrens = section.children;
      let subsections = childrens.filter(function (child) {
        return child.elementId === subsectionId;
      });
      if (subsections.length > 0) {
        let subsection = subsections[0];
        subsection.children.push(element);
        return element;
      }
      return null;
    } else {
      section.children.push(element);
    }
    return element;
  }

  static addCopiedElementTo(
    form,
    section,
    selectedSubsection,
    selectedQuestion,
    subsection,
    position,
    subsectionArray
  ) {
    let { id, motherId, order, ...copiedSubsection } = subsection;
    let namesArray = FormHelperService.getElementNameList(form);
    copiedSubsection.label = 'Copy-' + copiedSubsection.label;
    copiedSubsection.name = FormHelperService.generateElementName(
      copiedSubsection.componentType,
      namesArray
    );
    let elementId = FormHelperService.generateElementId();
    copiedSubsection.elementId = elementId;
    copiedSubsection.children = [];
    const sectionIndex = element => element.elementId === section.elementId;
    const index = form.children.findIndex(sectionIndex);
    const indexofSelectedSubsection = element => element.elementId === selectedSubsection.elementId;
    const selectedSubsectionIndex =
      form.children[index].children.findIndex(indexofSelectedSubsection);
    let subsectionIndex = 0;
    if (position === 'before') {
      subsectionIndex = selectedSubsectionIndex === 0 ? 0 : selectedSubsectionIndex;
      form.children[index].children.splice(subsectionIndex, 0, copiedSubsection);
    } else {
      subsectionIndex = selectedSubsectionIndex + 1;
      form.children[index].children.splice(subsectionIndex, 0, copiedSubsection);
    }
    if (subsection.children.length > 0) {
      subsection.children.forEach(element => {
        var { id, motherId, order, ...newObject } = element;
        let namesArrayNew = FormHelperService.getElementNameList(form);
        newObject.name = FormHelperService.generateElementName(
          newObject.componentType,
          namesArrayNew
        );
        newObject.elementId = FormHelperService.generateElementId();
        copiedSubsection.children.push(newObject);
      });
    } else {
      if (selectedSubsection) {
        const indexofSelectedSubsection = element =>
          element.elementId === selectedSubsection.elementId;
        const selectedSubsectionIndex =
          form.children[index].children.findIndex(indexofSelectedSubsection);
        let subsectionIndex = selectedSubsectionIndex;
        if (position === 'before') {
          subsectionIndex = selectedSubsectionIndex === 0 ? 0 : selectedSubsectionIndex;
          form.children[index].children.splice(subsectionIndex, 0, copiedSubsection);
        } else {
          subsectionIndex = selectedSubsectionIndex + 1;
          form.children[index].children.splice(subsectionIndex, 0, copiedSubsection);
        }
        return form.children[index].children;
      } else {
        const indexofSelectedQuestion = element => element.elementId === selectedQuestion.elementId;
        const selectedquestionIndex =
          form.children[index].children.findIndex(indexofSelectedQuestion);
        let questionIndex = selectedquestionIndex;
        if (position === 'before') {
          questionIndex = selectedquestionIndex === 0 ? 0 : selectedquestionIndex;
          form.children[index].children.splice(questionIndex, 0, copiedSubsection);
        } else {
          questionIndex = selectedquestionIndex + 1;
          form.children[index].children.splice(questionIndex, 0, copiedSubsection);
        }
        return form.children[index].children;
      }
    }
  }

  static copyQuestionToSelectedPosition(
    form,
    section,
    selectedSubsection,
    selectedQuestion,
    questionElement,
    position,
    expandedSectionId,
    currentSubsectionId,
    type
  ) {
    let question = questionElement;
    if (type === 'move') {
      var copiedQuestion = question;
      let sections = form.children;
      let expandedSectionArray = sections.filter(function (child) {
        return child.elementId === expandedSectionId;
      });
      let expandedSection = expandedSectionArray[0];
      let expandedSectionChildren = expandedSection.children;
      let activeElementArray = expandedSectionChildren.findIndex(function (child) {
        return child.elementId === questionElement.elementId;
      });
      if (activeElementArray === -1) {
        let subsections = expandedSectionChildren.filter(
          ele => ele.elementId === currentSubsectionId
        );
        activeElementArray = subsections[0].children.findIndex(
          ele => ele.elementId === questionElement.elementId
        );
        subsections[0].children.splice(activeElementArray, 1);
      } else {
        expandedSectionChildren.splice(activeElementArray, 1);
      }
    } else {
      let { id, motherId, order, ...copyQuestion } = question;
      let namesArray = FormHelperService.getElementNameList(form);
      copyQuestion.label = 'Copy-' + copyQuestion.label;
      copyQuestion.name = FormHelperService.generateElementName(
        copyQuestion.componentType,
        namesArray
      );
      let elementId = FormHelperService.generateElementId();
      copyQuestion.elementId = elementId;
      copiedQuestion = copyQuestion;
    }

    const sectionIndex = element => element.elementId === section.elementId;
    const index = form.children.findIndex(sectionIndex);

    if (selectedSubsection) {
      const indexofSelectedSubsection = element =>
        element.elementId === selectedSubsection.elementId;
      const selectedSubsectionIndex =
        form.children[index].children.findIndex(indexofSelectedSubsection);
      const indexofSelectedQuestion = element => element.elementId === selectedQuestion.elementId;
      const selectedquestionIndex =
        form.children[index].children[selectedSubsectionIndex].children.findIndex(
          indexofSelectedQuestion
        );
      let questionIndex = selectedquestionIndex;
      if (position === 'before') {
        questionIndex = selectedquestionIndex === 0 ? 0 : selectedquestionIndex;
        form.children[index].children[selectedSubsectionIndex].children.splice(
          questionIndex,
          0,
          copiedQuestion
        );
      } else {
        questionIndex = selectedquestionIndex + 1;
        form.children[index].children[selectedSubsectionIndex].children.splice(
          questionIndex,
          0,
          copiedQuestion
        );
      }
      return form.children[index].children[selectedSubsectionIndex].children;
    } else {
      const indexofSelectedQuestion = element => element.elementId === selectedQuestion.elementId;
      const selectedquestionIndex =
        form.children[index].children.findIndex(indexofSelectedQuestion);
      let questionIndex = selectedquestionIndex;
      if (position === 'before') {
        questionIndex = selectedquestionIndex === 0 ? 0 : selectedquestionIndex;
        form.children[index].children.splice(questionIndex, 0, copiedQuestion);
      } else {
        questionIndex = selectedquestionIndex + 1;
        form.children[index].children.splice(questionIndex, 0, copiedQuestion);
      }
      return copiedQuestion;
    }
  }

  static addNewElementToActiveSection(form, activeSectionId, type, subsectionId) {
    if (!form || !activeSectionId || !type) {
      return null;
    }

    let filteredSections = form.children.filter(function (section) {
      return section.elementId === activeSectionId;
    });

    if (filteredSections.length > 0) {
      return FormHelperService.addNewElementTo(form, filteredSections[0], type, subsectionId);
    }

    return null;
  }

  static addCopiedElementtoSelectedSection(
    form,
    activeSectionId,
    selectedSubsection,
    selectedQuestion,
    copySubsection,
    position,
    expandedSectionId,
    activeSubsectionId
  ) {
    if (!form || !activeSectionId) {
      return null;
    }

    let filteredSections = form.children.filter(function (section) {
      return section.elementId === activeSectionId;
    });

    let expandedSection = form.children.filter(function (section) {
      return section.elementId === expandedSectionId;
    });
    let filteredSubsections = expandedSection[0].children.filter(function (subsection) {
      return subsection.elementId === activeSubsectionId;
    });

    if (filteredSections.length > 0) {
      return FormHelperService.addCopiedElementTo(
        form,
        filteredSections[0],
        selectedSubsection,
        selectedQuestion,
        copySubsection,
        position,
        filteredSubsections[0]
      );
    }
    return null;
  }

  static copyOrMoveElementtoSelectedSection(
    form,
    activeSectionId,
    selectedSubsection,
    selectedQuestion,
    question,
    position,
    expandedSectionId,
    currentSubsectionId,
    type
  ) {
    if (!form || !activeSectionId) {
      return null;
    }
    let filteredSections = form.children.filter(function (section) {
      return section.elementId === activeSectionId;
    });

    if (filteredSections.length > 0) {
      return FormHelperService.copyQuestionToSelectedPosition(
        form,
        filteredSections[0],
        selectedSubsection,
        selectedQuestion,
        question,
        position,
        expandedSectionId,
        currentSubsectionId,
        type
      );
    }
    return null;
  }

  static removeSection(form, selectedSection) {
    const selectedSectionIndex = element => element.elementId === selectedSection.elementId;
    if (form.children) {
      const index = form.children.findIndex(selectedSectionIndex);
      if (index !== -1) {
        let sectionIndex = index;
        form.children.splice(sectionIndex, 1);
      }
    }
  }

  static removeSubsectionFromSection(form, expandedSectionId, subsection) {
    let expandedSection = form.children.filter(function (child) {
      return child.elementId === expandedSectionId;
    });
    let expandedSectionChildren = expandedSection[0].children;
    const selectedSubsectionIndex = element => element.elementId === subsection.elementId;
    const index = expandedSectionChildren.findIndex(selectedSubsectionIndex);
    if (subsection.elementId !== undefined && index !== -1) {
      let subsectionIndex = index;
      expandedSectionChildren.splice(subsectionIndex, 1);
    }
  }

  static removeElementFromSection(form, expandedSectionId, deleteEle, subsectionId) {
    let expandedSectionArray = form.children.filter(function (child) {
      return child.elementId === expandedSectionId;
    });
    let expandedSectionChildren = expandedSectionArray[0].children;
    if (subsectionId !== undefined) {
      let subsection = expandedSectionChildren.filter(function (child) {
        return child.elementId === subsectionId;
      });
      let children = subsection[0].children;
      children.splice(
        children.findIndex(e => e.elementId === deleteEle.elementId),
        1
      );
    } else {
      expandedSectionChildren.splice(
        expandedSectionChildren.findIndex(e => e.elementId === deleteEle.elementId),
        1
      );
    }
  }

  static getActiveElementById(form, expandedSectionId, activeElementId, subsectionId) {
    let sections = form.children;
    let expandedSectionArray = sections.filter(function (child) {
      return child.elementId === expandedSectionId;
    });
    let expandedSection = expandedSectionArray[0];
    let expandedSectionChildren = expandedSection.children;
    let activeElementArray = expandedSectionChildren.filter(function (child) {
      return child.elementId === activeElementId;
    });
    if (!activeElementArray.length) {
      let subsections = expandedSectionChildren.filter(ele => ele.elementId === subsectionId);
      if (subsections.length > 0) {
        activeElementArray = subsections[0].children.filter(
          ele => ele.elementId === activeElementId
        );
      }
    }
    return activeElementArray[0];
  }

  static addHelpText(form, expandedSectionId, activeElementId, currentSubsectionId, helpText) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    activeElement.helpImageText = helpText;
  }

  static addOptions(form, expandedSectionId, activeElementId, currentSubsectionId, options) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    activeElement.options = options;
  }

  static addRadioGroupOptions(
    form,
    expandedSectionId,
    activeElementId,
    currentSubsectionId,
    options,
    conditionals
  ) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    activeElement.options = options;
    activeElement.conditional = conditionals;
  }

  static addPopupOptions(
    translateForm,
    translateSectionId,
    activeTranslateElementId,
    translateSubsectionId,
    popupOptions
  ) {
    let activePopupElement = FormHelperService.getActiveElementById(
      translateForm,
      translateSectionId,
      activeTranslateElementId,
      translateSubsectionId
    );
    activePopupElement.options = popupOptions;
  }

  static addDependentDropdownOptions(
    form,
    expandedSectionId,
    activeElementId,
    currentSubsectionId,
    dynamicDropdownList
  ) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    activeElement.dynamicDropdownList = dynamicDropdownList;
  }

  static toggleQuestionAttr(form, expandedSectionId, activeElementId, currentSubsectionId, obj) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    if (activeElement[obj.attr]) {
      activeElement[obj.attr][obj.name] = obj.value;
    } else {
      activeElement[obj.attr] = {};
      activeElement[obj.attr][obj.name] = obj.value;
    }
  }

  static assignUniqueCodeToQuestion(
    form,
    expandedSectionId,
    activeElementId,
    currentSubsectionId,
    uniqueCodeObj
  ) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    activeElement.uniqueCode = uniqueCodeObj;
  }

  static deleteUniqueCodeFromQuestion(
    form,
    expandedSectionId,
    activeElementId,
    currentSubsectionId
  ) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    if (activeElement['uniqueCode']) {
      delete activeElement['uniqueCode'];
    }
  }

  static findElementFromSection(sectionChildren, activeElement, subsectionId, questionList) {
    let isElementFound = false;
    sectionChildren.forEach(subsection => {
      if (isElementFound && subsection.componentType !== 'subsection') {
        questionList.push(subsection);
      } else if (subsection.elementId === activeElement) {
        isElementFound = true;
      }
    });
    if (!isElementFound) {
      let activeSubsection = sectionChildren.find(sub => {
        return sub.elementId === subsectionId;
      });
      FormHelperService.findElementFromSection(
        activeSubsection.children,
        activeElement,
        subsectionId,
        questionList
      );
    }
    return questionList;
  }

  static getQuestionsList(form, sectionId, subsectionId, activeElement) {
    var questionList = [];
    let sections = form.children.find(section => {
      return section.elementId === sectionId;
    });
    return FormHelperService.findElementFromSection(
      sections.children,
      activeElement,
      subsectionId,
      questionList
    );
  }

  static saveOptionQuestions(
    form,
    expandedSectionId,
    activeElementId,
    subsectionId,
    optionId,
    targets,
    conditionals
  ) {
    let updatedConditional = FormHelperService.setConditionalsForQuestion(
      optionId,
      targets,
      conditionals
    );
    let conditionalArray = updatedConditional.filter(conditional => {
      return conditional.id === optionId;
    });
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      subsectionId
    );
    if (activeElement) {
      let conditionals = activeElement.conditional;
      var formConditionals = JSON.parse(JSON.stringify(conditionals));
      var conditional = formConditionals.find(cond => {
        return cond.id === optionId;
      });
      if (formConditionals) {
        let existingActions = conditionalArray[0].actions;
        let filteredActions = existingActions.filter(
          action =>
            action.name !== QUESTION_LOGIC.SHOW.name && action.name !== QUESTION_LOGIC.HIDE.name
        );
        let updatedActions = filteredActions.concat(targets);
        conditional.actions = updatedActions;
      }
      const conditionalIndex = conditionals.findIndex(cond => {
        return cond.id === optionId;
      });
      conditionals[conditionalIndex] = JSON.parse(JSON.stringify(conditional));
      return activeElement;
    }
  }

  static setConditionalsForQuestion(optionId, targets, conditionals) {
    const conditionalIndex = conditionals.findIndex(cond => {
      return cond.id === optionId;
    });
    conditionals.filter(conditional => {
      if (conditional.id === optionId) {
        if (conditional === undefined && targets) {
          conditional = {};
          conditional.id = optionId;
          conditional.value = '';
          conditional.actions = targets;
        } else if (conditional !== undefined && targets && conditionalIndex !== -1) {
          conditional.actions = targets;
        }
      }
    });
    return conditionals;
  }

  static selectedActionForOption(form, expandedSectionId, activeElementId, subsectionId, optionId) {
    let conditional = FormHelperService.conditionalsFor(
      form,
      expandedSectionId,
      activeElementId,
      subsectionId,
      optionId
    );
    return conditional;
  }

  static getConditionalfromForm(form, expandedSectionId, activeElementId, subsectionId) {
    if (form && expandedSectionId && activeElementId) {
      let activeElement = FormHelperService.getActiveElementById(
        form,
        expandedSectionId,
        activeElementId,
        subsectionId
      );
      if (activeElement) {
        let conditionals = activeElement.conditional;
        if (conditionals) {
          return conditionals;
        }
      }
    }
  }

  static performAction(
    options,
    typeOfAction,
    index = 0,
    value = '',
    elementType = '',
    startIndex = 0,
    endIndex = 0
  ) {
    let arr = Array.from(options);

    if (typeOfAction === ACTIONS.ADD) {
      if (elementType === ELEMENT_TYPE.CHECKBOX) {
        arr.push({ checked: false, optionText: 'Option Label' });
      } else if (elementType === ELEMENT_TYPE.RADIO) {
        arr.push({ selected: false, optionText: 'Option Label', id: this.generateElementId() });
      } else if (elementType === ELEMENT_TYPE.DROPDOWN) {
       arr.push({ value: 'Option Label', id: this.generateElementId() });
      }
    } else if (typeOfAction === ACTIONS.DELETE) {
      arr.splice(index, 1);
    } else if (typeOfAction === ACTIONS.EDIT) {
      if (elementType === ELEMENT_TYPE.DROPDOWN) {
        arr[index].value = value;
      } else {
        arr[index].optionText = value;
      }
    } else if (typeOfAction === ACTIONS.MOVE) {
      const [removed] = arr.splice(startIndex, 1);
      arr.splice(endIndex, 0, removed);
    }

    return arr;
  }

  static conditionalsFor(form, expandedSectionId, activeElementId, subsectionId, optionId) {
    if (form && expandedSectionId && activeElementId) {
      let activeElement = FormHelperService.getActiveElementById(
        form,
        expandedSectionId,
        activeElementId,
        subsectionId
      );
      if (activeElement) {
        let conditionals = activeElement.conditional;
        if (conditionals) {
          const conditional = conditionals.find(cond => {
            return cond.id === optionId;
          });
          return conditional;
        }
      }
    }
    return null;
  }

  static addConditionalsForOption(
    form,
    expandedSectionId,
    activeElementId,
    subsectionId,
    optionId,
    actions
  ) {
    if (form && expandedSectionId && activeElementId && actions) {
      let activeElement = FormHelperService.getActiveElementById(
        form,
        expandedSectionId,
        activeElementId,
        subsectionId
      );
      if (activeElement) {
        var existingConditionals = activeElement.conditional;
        var conditional, actionIndex;
        if (!existingConditionals) {
          existingConditionals = [];
        } else {
          actionIndex = existingConditionals.findIndex(cond => {
            return cond.id === optionId;
          });
        }
        if (actionIndex !== -1 && actionIndex !== undefined) {
          existingConditionals[actionIndex].actions = actions;
        } else {
          conditional = {};
          conditional.id = optionId;
          conditional.value = '';
          conditional.actions = actions;
          existingConditionals.push(conditional);
        }
        activeElement.conditional = existingConditionals;
        return form;
      }
    }
  }

  static updateElementProperties(
    form,
    expandedSectionId,
    activeElementId,
    currentSubsectionId,
    name,
    value
  ) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    activeElement[name] = value;
  }

  static addHelpImage(form, expandedSectionId, activeElementId, currentSubsectionId, image) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    if (activeElement.imagesData) {
      activeElement.imagesData.push(image);
    } else {
      activeElement.imagesData = [];
      activeElement.imagesData.push(image);
    }
    return activeElement.imagesData;
  }

  static removeHelpImage(form, expandedSectionId, activeElementId, currentSubsectionId, imageId) {
    let activeElement = FormHelperService.getActiveElementById(
      form,
      expandedSectionId,
      activeElementId,
      currentSubsectionId
    );
    if (activeElement.imagesData) {
      let images = activeElement.imagesData;
      let index = _.findIndex(images, { imageId: imageId });
      images.splice(index, 1);
      activeElement.imagesData = images;
      return activeElement.imagesData;
    }
  }
}
