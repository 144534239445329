import React, { Component } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddPhotoAlternateOutlinedIcon 
	from "@material-ui/icons/AddPhotoAlternateOutlined";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import RemoveCircleOutlineOutlinedIcon 
	from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { messages, GeneralConfig } from '../../utility/Constant';
import { FormStore } from "../../services/form.store";
import { FormInputTypesValidationService } 
	from '../../services/form-input-types-validations.service';
import { ATTRIBUTES } from '../../services/form.helper.service';
import FormBuilderService from '../../services/form-builder.service';
import { toast } from 'react-toastify';
import _ from 'lodash';

const styles = (theme) => ({
  button: { margin: theme.spacing(1) },
  addOptionButton: {
    marginTop: "10px", color: "#757a76", marginLeft: "-7px",
    border: "1px solid gray"
  },
  activeTextField: { backgroundColor: "rgba(255, 255, 255, .75)" },
  dividerStyle: { width: 1, height: 40 },
  dividerStyleNameBlock: { marginLeft: 15 },
  toolpalete: { marginLeft: "108px" },
  input: { display: "none" },
  fileName: { padding: "5px", marginTop: "15px" },
  error: { 
	color: 'red', padding: '4px', fontSize: 14, width: '-webkit-fill-available' 
  },
  borderRed: { border: '1px solid red' },
  thumbnail: { display:'contents' },
  thumbnailInner: {
	display: 'inline-block', width: '190px', paddingBottom: '7px'
  },
  box: {marginLeft: '-7px'},
  container: {width: 'calc(100% - 288px)'},
  imageButton: {marginLeft: '7px'},
  removeImageIcon: {
    position: 'relative', left: '168px', top: '13px', display: 'block',
    color: 'red', backgroundColor: 'white', borderRadius: '50%', 
    cursor: 'pointer'
  }
});

class InstructionHelp extends Component {
  constructor(props) {
    super();
    this.FormBuilderService = new FormBuilderService();
    this.state = {
      errorMessage: '', showTextError: false, showImageEror: false,
      imageErrorMessage: '',
      files: props.element.imagesData ? props.element.imagesData : [], 
      hasHelpImage: props.element.hasHelpImage ? props.element.hasHelpImage : 
    	false,
      helpImageText: props.element.helpImageText? props.element.helpImageText : 
    	messages.LOREM_IPSUM
    };
  }
  
  componentWillReceiveProps() {
	if(!this.props.element.properties.hasHelpImage) 
	  this.setState({ files: [] });
  }

  generateImageId() {
	return (this.props.element.id !== undefined ? this.props.element.id : 
	  new Date().getFullYear()) + '-' + Date.now() + '-' + 
	  this.state.files.length;
  };
	  
  uploadFile = (e) => {
	let file = e.target.files[0];
	let files = this.state.files;
	let id = '';
	let fileName = file.name;
	let fileNotAvailable = _.filter(files, file => file.imageName === fileName);
	if(fileNotAvailable.length === 0) {
	  id = this.generateImageId();
	  let fileObj = { 
		imageName: fileName, imageUrl: URL.createObjectURL(file), imageId: id, 
		file: file
	  };
	  this.FormBuilderService.uploadImageDetails(fileObj).then((response) => {
		document.getElementById('contained-button-file').value = '';
		document.getElementById('contained-button-file').type = '';
		document.getElementById('contained-button-file').type = 'file';
  	    if(response === undefined) 
		  toast.error("Something went wrong. Please try after some time.");
		else {
		  fileObj.imageUrl = response.attachments[0].file;
		  delete fileObj.file;
		  let images = FormStore.sharedInstance().addHelpImage(fileObj);
		  this.setState({ files: images });
		}
	  });
	} else toast.error("Image " + fileName + " already exists.");
  };

  removeImage = (id) => {
	this.FormBuilderService.imageDetails(id, GeneralConfig.DELETE).then((response) => {
	  if(response && response.status && response.status === 200) {
		let images = FormStore.sharedInstance().removeHelpImage(response.data.imageId);
		this.setState({ files: images });
	  } else {
		toast.error("Could not delete image.");
	  }
	});
  };

  onChange = (text) => {
    let plainText = text.replace(/(<([^>]+)>)/ig, '');
    let errorMessage = FormInputTypesValidationService.validateCommonAttributes(
    		ATTRIBUTES.INSTRUCTION_HELP_TEXT, plainText);
    this.setState({ 
    	errorMessage: errorMessage, 
    	showTextError: errorMessage.length > 0 ? true : false
    }, () => {
      if (this.state.errorMessage === '') this.updateState(text);
      else FormStore.sharedInstance().addErrorId(this.props.elementId);
    });
  }

  updateState(text) {
    this.setState({ helpImageText: text, showTextError: false }, () => {
      FormStore.sharedInstance().addHelpText(this.state.helpImageText);
      FormStore.sharedInstance().deleteErrorId(this.props.elementId);
    });
  }

  render() {
    const { classes } = this.props;
    const { files } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box>
          <Box>
            <Grid container className={classes.container}>
              <SunEditor defaultValue={this.state.helpImageText} id="plainText" 
            	  height='auto' minHeight='50'
                  setOptions={{
                    buttonList: [
                      [
                        'bold', 'italic', 'underline', 'list', 'align', 
                        'fontColor', 'hiliteColor'
                      ]
                    ],
                    colorList: [
                      [
                        '#ff0000', '#ff5e00', '#ffe400', '#abf200', '#00d8ff', '#ffffff',
                        '#0055ff', '#6600ff', '#ff00dd', '#000000'
                      ]
                    ],
                    showPathLabel: false, resizingBar: false
                  }}
                  onChange={this.onChange} />
              {(this.state.errorMessage !== '' && this.state.showTextError) ? 
            	<label className={classes.error}>
              		{this.state.errorMessage}
            	</label> 
              : null}
              {(this.props.element.properties && 
                this.props.element.properties.hasHelpImage) ? 
                	<Box pt={1} pb={2} pl={2} pr={1} className={classes.box} >
                		{files.map((item, index) => (
                          (index < GeneralConfig.MAX_IMAGE_COUNT) ? 
                        	<div className={classes.thumbnail} key={index} >
                          		<div className={classes.thumbnailInner}>
                          			<RemoveCircleOutlineOutlinedIcon 
                          				className={classes.removeImageIcon} 
                      					onClick={() => this.removeImage(item.imageId)} />
                  					<img src={item.imageUrl} width="180" 
                  						height="120" />
                  				</div>
                  			</div> : 
                  			null
                		))}
                		{files.length === GeneralConfig.MAX_IMAGE_COUNT ? 
                    	  <label className={classes.error}>
                      		{messages.CANNOT_UPLOAD_MORE_IMAGES}
                    	  </label> 
                        : null}
                        <input accept="image/*" type="file"
                          className={classes.input} id="contained-button-file"
                          onChange={this.uploadFile} 
                          disabled={this.state.files.length===3}/>
                        <div className={classes.imageButton}>
                          <label htmlFor="contained-button-file">
                            <Button variant="contained outlined" 
                              id="addOptionButton" component="span" 
                              className={classes.addOptionButton}
                              startIcon={<AddPhotoAlternateOutlinedIcon />}>
                              Add Image
                            </Button>
                          </label>
                        </div>
                    </Box> 
              : null}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(InstructionHelp);