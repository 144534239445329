import { BehaviorSubject } from 'rxjs';
import FormFillerService from '../../services/review-portal-services/form-filler.service';
import { mockForm } from './mock.data';
import { myForms } from './mock.myforms.data';
import ReviewPortalService from '../../services/review-portal-services/review-portal.service';
import { toast } from 'react-toastify';
import { addAnswer } from './form.answer.fill';
import { validate } from '../Validations/form.validators';
import { currentPage } from '../views/MyForms';
import FormBuilderService from '../../services/form-builder.service';
import { htmlBase64Utils } from '../../utility/CommonUtils';

var currentFormId = undefined;
var expandedSectionId = undefined;
var currentSectionId = undefined;
var currentSubsectionId = undefined;
var searchParams = {};
var formErrors = {};
var currentSortParameter = {};
var rowsPerPageSize = 10;
const isMockMyFormFlow = false;
const isMockForm = false;

const parentDropDownId = new BehaviorSubject('');
const formState = new BehaviorSubject({});
const myFormsState = new BehaviorSubject([]);
const myFormsPageState = new BehaviorSubject();
const sitesState = new BehaviorSubject([]);
const sitesPageState = new BehaviorSubject();
const loaderState = new BehaviorSubject(false);
const openSectionState = new BehaviorSubject(expandedSectionId);
const currentSectionState = new BehaviorSubject(currentSectionId);
const currentSubsectionState = new BehaviorSubject(currentSubsectionId);
const formFillerService = new FormFillerService();
const reviewPortalService = new ReviewPortalService();
const formBuilderService = new FormBuilderService();
const formErrorState = new BehaviorSubject(formErrors);
const sortingSubject = new BehaviorSubject(currentSortParameter);
const turbinesState = new BehaviorSubject([]);
const turbinesPageState = new BehaviorSubject();

class FormStore {
    static_instance = null;
    constructor() {
        this.form = {};
        this.updatedForm = {};
        this.myForms = [];
        this.myFormsFilteredList = [];
    }

    static sharedInstance() {
        if (FormStore._instance == null) {
            FormStore._instance = new FormStore();
        }
        return this._instance;
    }

    loadMyForms = () => {
        if (isMockMyFormFlow) {
            if (myForms) {
                this.myForms = myForms;
                this.didLoadMyForms(this.myForms);
            }
            return;
        } else {/*api call to be integrated for initial form list, for now considering empty list as initial state.*/
            this.didLoadMyForms([]);
        }
    }

    filterMyForms = (searchCriteria, rowsLength) => {
        searchParams = searchCriteria;
        rowsPerPageSize = rowsLength;
        if (isMockMyFormFlow) {
            if (myForms && myForms.content) {
                this.didLoadMyForms(myForms);
            }
            return;
        }
        this.onLoading(true)
        reviewPortalService.getAllFormsBySortingorSearching(searchCriteria, currentSortParameter, currentPage, rowsPerPageSize)
            .then((searchResults) => {
                this.didLoadMyForms(searchResults.data.content);
                this.didLoadMyFormsWithPagination(searchResults);
            })
            .catch((err) => { return err });
    }

    didLoadMyForms(forms) {
        this.onLoading(false);
        this.myFormsFilteredList = forms;
        myFormsState.next(this.myFormsFilteredList);
    }

    didLoadMyFormsWithPagination(forms) {
        this.onLoading(false);
        myFormsPageState.next(forms);
    }

    onLoading = (isLoading) => {
        loaderState.next(isLoading);
    }

    load = (formId) => {
        this.resetState();
        currentFormId = formId;
        this.loadFormInternal(formId);
    }

    loadFormInternal = (formId) => {
        currentFormId = formId;
        this.onLoading(true);
        if (isMockForm) {
            this.initializeForm(mockForm);
            return;
        }
        if (currentFormId === undefined) {
            console.log('Empty or Undefined formId');
        }
        formFillerService.getForm(formId).then((res) => {
            this.initializeForm(res.data)
        });
    }

    initializeForm = (formInfo) => {
        this.form = formInfo
        this.updatedForm = JSON.parse(JSON.stringify(formInfo))
        formState.next(formInfo);
        this.onLoading(false);
    }

    openSection = (id) => {
        expandedSectionId = (expandedSectionId !== id) ? id : undefined;
        openSectionState.next(expandedSectionId);
    }

    currentSection = (id) => {
        // currentSectionId = (currentSectionId !== id) ? id : undefined;
        currentSectionId = id
        currentSectionState.next(currentSectionId)
    }

    openSubSection = (id) => {
        currentSubsectionId = (currentSubsectionId !== id) ? id : undefined;
        currentSubsectionState.next(currentSubsectionId);
    }

    resetState = () => {
        formErrors = {}
        expandedSectionId = undefined
        currentSectionId = undefined
        currentSubsectionId = undefined
        openSectionState.next(expandedSectionId)
        currentSectionState.next(currentSectionId)
        currentSubsectionState.next(currentSubsectionId)
        formErrorState.next(formErrors)
    }

    parentDropDownId = (parentSelectedObject) => {
        parentDropDownId.next(parentSelectedObject);
    };

    deleteForm = (formId) => {
        this.onLoading(true);
        formFillerService.deleteForm(formId).then((res) => {
            this.onLoading(false);
            if (res.status !== 200) {
                toast.error("Could not delete form, " + res.message);
                return
            }
            toast.success("Form deleted successfully!");
            this.filterMyForms(searchParams, rowsPerPageSize);
        })
    }

    approveForm = (formObject) => {
        this.onLoading(true);
        formFillerService.approveForm(formObject).then((res) => {
            this.onLoading(false);
            if (res.status !== 200) {
                toast.error("Could not approve form, " + res.message);
                return
            }
            toast.success("Form Approved successfully!");
            this.filterMyForms(searchParams, rowsPerPageSize);
            this.load(res.data);
        })
    }

    rejectForm = (formObject) => {
        this.onLoading(true);
        formFillerService.rejectForm(formObject).then((res) => {
            this.onLoading(false);
            if (res.status !== 200) {
                toast.error("Could not reject form, " + res.message);
                return
            }
            toast.success("Form Rejected successfully!");
            this.filterMyForms(searchParams, rowsPerPageSize);
            this.load(res.data);
        })
    }

    downloadForm = (form) => {
        this.onLoading(true);
        formFillerService.downloadForm(form.formDataId).then((res) => {
            this.onLoading(false);
            if (res.status !== 200) {
                toast.error("Could not download form, " + res.message);
                return
            } else {
                let blob = new Blob([res.data], { type: 'application/pdf' });
                let downloadLink = document.createElement('a');
                let fileURL = URL.createObjectURL(blob);
                downloadLink.href = fileURL;
                downloadLink.download = form.formName + '_' + form.formDataId;
                downloadLink.click();
                URL.revokeObjectURL(fileURL);
                toast.success("Form downloaded successfully!");
            }
        });
    }

    downloadSelectedForms = (formIds) => {
        formFillerService.downloadSelectedForms(formIds).then((res) => {
            this.onLoading(false);
            if (res.status !== 200) {
                toast.error("Could not download forms, " + res.message);
                return
            } else {
                let blob = new Blob([res.data], { type: 'application/zip' });
                let downloadLink = document.createElement('a');
                let fileURL = URL.createObjectURL(blob);
                downloadLink.href = fileURL;
                downloadLink.download = 'Receiving_Inspection_'+Date.now();
                downloadLink.click();
                URL.revokeObjectURL(fileURL);
                toast.success("Form downloaded successfully!");
            }
        });
    }

    recordAnswer(answer, element) {
        let errors = validate(element, answer);
        this.updateError(element.id, errors);
        let updatedForm = addAnswer(answer, element.id, this.updatedForm);
        if (updatedForm) {
            this.updatedForm = updatedForm
            formState.next(updatedForm);
        }
    }

    updateError(elementId, errors) {
        if (elementId) {
            if (errors.length === 0) {
                delete formErrors[elementId]
            } else {
                formErrors[elementId] = errors;
            }
            formErrorState.next(formErrors);
        }
    }

    resetForm = () => {
        this.resetState()
        formState.next(JSON.parse(JSON.stringify(this.form)))
    }

    saveForm = () => {
        this.onLoading(true)
        htmlBase64Utils(this.updatedForm, true);
        formFillerService.saveForm(this.updatedForm.formDataId, this.updatedForm).then((res) => {
            if (res.status !== 200) {
                toast.error("Could not save form, " + res.message);
                this.onLoading(false);
                return
            }
            this.resetState()
            toast.success("Form saved successfully!");
            this.initializeForm(res.data)
            this.onLoading(false);
        });
    }

    sortForms = (sortParameter) => {
        currentSortParameter = sortParameter;
        this.sortFormsInternal(currentSortParameter);
    }

    onSortChanged = (sortParameter) => {
        sortingSubject.next(sortParameter);
    }

    sortFormsInternal = (sortParameter) => {
        if (isMockMyFormFlow) {
            if (myForms) FormStore.sharedInstance().didLoadMyForms(myForms);
            return;
        }
        this.onSortChanged(sortParameter);
        this.fireApi();
    }

    pageChanged = () => {
        if (isMockMyFormFlow) {
            if (myForms) FormStore.sharedInstance().didLoadMyForms(myForms);
            return;
        }
        this.fireApi();
    }

    getAllTurbinesBySiteId = (siteId) => {
        this.didLoadTurbines([]);
        formBuilderService.getAllTurbinesBySite(siteId).then(response => {
            if(response.status === 200 || response.status === 204){
                this.didLoadTurbines(response.data.turbines);
                this.didLoadTurbinesWithPagination(response);
            }
        });
    }

    didLoadTurbines = (turbines) => {
        turbinesState.next(turbines);
    }

    didLoadTurbinesWithPagination = (turbines) => {
        turbinesPageState.next(turbines);
    }

    fireApi = () => {
        // When search query is present
        if (Object.keys(searchParams).length !== 0) { // If search query is present, trigger search api. For >0 and <3 chars, Sorting will be on the results displayed from the last search
            reviewPortalService.getAllFormsBySortingorSearching(searchParams, currentSortParameter, currentPage, rowsPerPageSize)
                .then((res) => {
                    this.didLoadMyForms(res.data.content);
                    this.didLoadMyFormsWithPagination(res);
                });
        }
    }

}

export {
    FormStore,
    myFormsState,
    loaderState,
    formState,
    openSectionState,
    currentSectionState,
    currentSubsectionState,
    parentDropDownId,
    formErrorState,
    sortingSubject,
    myFormsPageState,
    sitesState,
    sitesPageState,
    turbinesState,
    turbinesPageState
}