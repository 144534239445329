import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import { FormStore } from '../../services/form.store';
import { QUESTION_LOGIC  } from '../../services/form.helper.service';

const HIDE = 'hide';
const SHOW = 'show';

export default function QuestionDialog(props) {
  const { onClose, open } = props;
  const [ visibility , setVisibility ] = useState(SHOW);
  const [ hiddenItems , setHiddenItems]= useState([]);
  const [ questionsList, setQuestionsList ] = useState([]);
  const [ items , setItems] = useState([
      { label: QUESTION_LOGIC.SHOW.label, name: QUESTION_LOGIC.SHOW.name,
    	icon: QUESTION_LOGIC.SHOW.icon, targets: []
      }, {  
    	label: QUESTION_LOGIC.HIDE.label, name: QUESTION_LOGIC.HIDE.name,
    	icon: QUESTION_LOGIC.HIDE.icon, targets: []
      }
  ]);
  const useStyles = makeStyles((theme) => ({
    root:{
      padding: theme.spacing(4),
      '&$checked': {
          color: green[600]
      }
    },
    dialogPaper: {
      minHeight: '70%', maxHeight: '70%', minWidth: '60%'
    },
    icon: {
      borderRadius: "50%", width: 25, height: 25,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      'input:disabled ~ &': {
        boxShadow: 'none', background: 'rgba(206,217,224,.5)'
      }
    },
    checkedIcon: {
      backgroundColor: green['500'], borderRadius: "50%",
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block', width: 25, height: 25,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""'
      }
    },
    labelDiveder:{
      marginLeft:'45px'
    },
    formControlElement:{
      paddingRight:25
    },
    dialogContent:{
      '&::-webkit-scrollbar': {
        width: "6px"
      },
      '&::-webkit-scrollbar-track': {
        background: "#FFFFFF"
      },
      '&::-webkit-scrollbar-thumb' :{
        background: "#D3D3D3" 
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: "#888" 
      }
    },
    dialogFooter:{
      padding:"8px 25px", backgroundColor: theme.palette.grey[100]
    },
    formLabel:{
      paddingLeft: "24px", paddingTop:"8px", color:"#747a84"
    },
    cancelButton:{
      boxShadow: 'none', backgroundColor: '#444a55', borderRadius: '24px',
      marginLeft: '10px', color: 'white', padding: '6px 24px',
      fontSize: '.85rem', letterSpacing: 1,
      '&:hover': {
        backgroundColor: '#444a55'
      }
    },
    toggleBtn:{
      padding: "0px 12px"
    },
    flexGrow:{
      flexGrow: 1
    },
    titleDialog:{
      backgroundColor : theme.palette.grey[100]
    },
    formControlLabel:{
      padding:"5px 0px"
    }
  }));

  const setDefaultValues = () => {
	  if(open) {
		let questionsList = FormStore.sharedInstance().getQuestionsList();
	    setQuestionsList(questionsList);
	    let conditional = FormStore.sharedInstance().selectedAction(props.id);
	    let targets = [];
	    if (conditional) {
	      let existingTargets = conditional.actions;
	      setItems((prevState) => (
	        prevState.map(item => {
	          let existingItem = existingTargets.find(target => target.name === item.name);
	          if(existingItem) 
            targets = existingItem.targets;
	          item.targets = targets;
	          return item;
	        })
	      ));
	    }
	  }
  }

  const handleCancel = () => {
    onClose();
  };

  const handleSave = () => {
    let conditionals = FormStore.sharedInstance().getConditional();
    conditionals = JSON.parse(JSON.stringify(conditionals));
    FormStore.sharedInstance().saveOptionQuestions(props.id, items, conditionals);
    onClose();
  };

  const onChangeQuestion = (e) => {
    let name = e.target.name;
    if(e.target.checked) {
      setItems((prevState) => (
        prevState.map(item => {
	        if(item.name === HIDE) {
	          item.targets = [...item.targets,  {elementId: name }];
	        } else {
	          item.targets = item.targets.filter(tItem => tItem.elementId !== name);
	        }
	        return item;
        })
      ));
      if(!hiddenItems.includes(name)) {
          setHiddenItems((prevState) => ([ ...prevState, name]));
      }
    } else {
      setItems((prevState) => (
        prevState.map(item => {
	        if(item.name === HIDE){
	          item.targets = item.targets.filter(teItem => teItem.elementId !== name);
	          if(visibility === SHOW) {
	            let array = [...hiddenItems];
	            let index = array.findIndex(temItem => temItem === e.target.name);
	            if (index !== -1) {
	              array.splice(index, 1);
	              setHiddenItems(array);
	            }
	          }
	        } else {
	          if(visibility === SHOW){
	            item.targets = [...item.targets,  { elementId: name }];
	          }
	        }
	        return item;
        })
      ));
    }
  }

  const filterSelected = (elementId) => {
    let arr = items.find(targets => targets.name === HIDE);
    return (arr.targets.filter(item => item.elementId === elementId)).length;
  }

  const isChecked = (item) => {
    return filterSelected(item.elementId) ? true : false;
  }

  const styles = (theme) => ({
    root: {
      margin: 0, padding: theme.spacing(6)
    },
    closeButton: {
      position: 'absolute', right: theme.spacing(4), top: theme.spacing(2), 
      color: theme.palette.grey[500]
    }
  });

  const DialogTitle = withStyles(styles)((props) => {
	  const { children, classes, onClose, ...other } = props;
	  return (
	    <MuiDialogTitle disableTypography className={classes.root} {...other}>
	      <Typography variant="h5">{children}</Typography>
	      {onClose ? (
	        <IconButton aria-label="close" className={classes.closeButton} 
	        	onClick={onClose}>
	          	<CloseIcon />
	        </IconButton>
	      ) : null}
	    </MuiDialogTitle>
	  );
  });
  let isExists = questionsList.length;
  let lastLabel = (isExists) ? isExists-1 : 0;
  const classes = useStyles();
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={open} 
      id="customized-dialog-title" className={classes.root} 
      classes={{ paper: classes.dialogPaper }} onEnter={setDefaultValues} >
      <DialogTitle id="dialog-title" onClose={handleCancel} 
      	className={classes.titleDialog}>Show / Hide Question
      </DialogTitle>
      { isExists ? 
      	<Typography className={classes.formLabel} variant="subtitle1">
      		Select question(s) to hide. If question(s) already selected, deselect to show.
      	</Typography> 
      : null }
      <DialogContent className={classes.dialogContent} >
      { isExists ? <FormControl component="fieldset" fullWidth>
	      <FormGroup>{ questionsList.map((item, index) => (
            <div>
              <FormControlLabel 
                  control={
                      <Checkbox name={item.elementId} 
                        checkedIcon={<span className={classes.checkedIcon} />}
                        icon={<span className={classes.icon} />}
                        checked={isChecked(item)}
                        onChange={onChangeQuestion} color="default"
                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                        className={classes.formControlElement} 
                      />
                  }
                  key={index} labelPlacement="end"
                  className={classes.formControlLabel} />
              <span dangerouslySetInnerHTML={{ __html: item.label }} 
              	style={{display: 'inline-block', marginLeft: '-20px'}}></span>
              {(lastLabel !== index) ? <Divider className={classes.labelDiveder} /> : null}
            </div>
	      )) }</FormGroup>
	    </FormControl> : <div style={{textAlign:'center'}}>No questions available.</div> }
      </DialogContent>
      <DialogActions className={classes.dialogFooter}>
        <div className={classes.flexGrow} />
        <Button size="medium" onClick={ handleCancel } disableElevation 
          className={classes.cancelButton} >Cancel
        </Button>
        {isExists ?
        <Button size="medium" onClick={handleSave} variant="contained" 
          disableElevation>{SHOW} / {HIDE}
        </Button> : null}
      </DialogActions>
    </Dialog>
  );
}